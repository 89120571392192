import React, { FC, PropsWithChildren } from 'react';

import { UiTruncateMarkup } from '../../truncate-markup';
import { UiTypography, UiTypographyTextProps } from '../../typography';

type Props = PropsWithChildren<{ typographyTextProps: Partial<UiTypographyTextProps> }>;

export const FileTruncatedWrapper: FC<PropsWithChildren<Props>> = (wrapperProps) => {
  const { children, typographyTextProps } = wrapperProps;

  if (typeof children === 'string') {
    return (
      <UiTypography.Text {...typographyTextProps}>
        <UiTruncateMarkup truncate tooltipProps={{ title: children }}>
          {children}
        </UiTruncateMarkup>
      </UiTypography.Text>
    );
  }

  return <>{children}</>;
};
