export const downloadFile = (file: Blob, name?: string | null) => {
  const a = document.createElement('a');

  document.body.appendChild(a);

  const blobURL = URL.createObjectURL(file);
  const fileName = name || blobURL.split('/').pop() || 'file';

  a.href = blobURL;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(blobURL);
  a.remove();

  return fileName;
};

export const downloadFileByURL = async (url: string, name?: string) => {
  const response = await fetch(url);

  const fileName = name || response.headers.get('Content-Disposition');
  const blob = await response.blob();

  return downloadFile(blob, fileName);
};
