import React, { PropsWithChildren, useMemo, useState } from 'react';

import { useSpace } from '../../../../hooks';
import { UiDnDActiveType, UiDnD, dndClosesCenterCollision } from '../../../dnd';
import {
  getDnDArrayMove,
  dndHorizontalListSortingStrategy,
  snapDnDItemLeftToCursor,
} from '../../../dnd/utils';
import { ColumnType, UiTableProps } from '../../UiTable';
import { UiTableDnDCellOverlay } from '../cell-overlay/UiTableDnDCellOverlay';
import { TableDnDConfigProps } from '../useTableDnDConfig';

type UiTableDnDContextHeaderProps<T extends object = Record<string, unknown>> = Pick<
  UiTableProps<T>,
  'columns'
> &
  Pick<TableDnDConfigProps<T>, 'onColumnDragEnd'>;

export const UiTableDnDContextHeader = <T extends Record<string, unknown>>(
  props: PropsWithChildren<UiTableDnDContextHeaderProps<T>>,
) => {
  const { children, columns = [], onColumnDragEnd, ...restProps } = props;
  const [activeItem, setActiveItem] = useState<UiDnDActiveType | null>(null);
  const { spaceXL } = useSpace();

  const activeColumn = useMemo(
    () => columns?.[activeItem?.data?.current?.sortable?.index],
    [activeItem, columns],
  );

  return (
    <thead {...restProps}>
      <UiDnD.Context<ColumnType<T>>
        modifiers={[snapDnDItemLeftToCursor({ horizontalShift: spaceXL })]}
        collisionDetection={dndClosesCenterCollision}
        onDragStart={({ active }) => {
          setActiveItem(active);
        }}
        onDragEnd={(event) => {
          const { active, over } = event;

          if (over && active.id !== over?.id && columns && onColumnDragEnd) {
            const activeIndex = active?.data?.current?.sortable?.index;
            const overIndex = over?.data?.current?.sortable?.index;

            if (typeof activeIndex === 'number' && typeof overIndex === 'number') {
              onColumnDragEnd(getDnDArrayMove(columns, activeIndex, overIndex));
            }
          }

          setActiveItem(null);
        }}
      >
        <UiDnD.Sortable.Context
          items={columns.map((item) => item.key as string)}
          strategy={dndHorizontalListSortingStrategy}
        >
          {children}
        </UiDnD.Sortable.Context>
        <UiDnD.Overlay>
          {activeColumn && (
            <UiTableDnDCellOverlay>
              {typeof activeColumn?.title === 'function' ? activeColumn?.title({}) : activeColumn?.title}
            </UiTableDnDCellOverlay>
          )}
        </UiDnD.Overlay>
      </UiDnD.Context>
    </thead>
  );
};
