import { Radio, RadioProps } from 'antd';
import { SpaceSize } from 'antd/lib/space';
import classNames from 'classnames';
import React, { FC, useContext } from 'react';

import { useGetSizeClassname } from '../../hooks';
import { UiConfigContext, UiConfigProviderSizeType } from '../config-provider';
import { UiSpace } from '../space';
import styles from './UiToggleButton.scss';

type ValueType = string | null | undefined;

export interface UiToggleButtonProps extends RadioProps {
  loading?: boolean;
  spaceSize?: SpaceSize;
  size?: UiConfigProviderSizeType;
  value: ValueType;
  block?: boolean;
}

export const UiToggleButton: FC<UiToggleButtonProps> = (props) => {
  const { loading, disabled, spaceSize, children, checked, className, size, block, ...restProps } = props;
  const isDisabled = loading || disabled;

  const { getPrefixCls } = useContext(UiConfigContext);
  const antInputCls = getPrefixCls('btn');
  const sizeCls = useGetSizeClassname(size);

  return (
    <Radio.Button
      disabled={isDisabled}
      className={classNames(className, styles.uiToggleButton, {
        [styles.uiToggleButton_checked]: checked,
        [styles.uiToggleButton_disabled]: isDisabled,
        [`${styles.uiToggleButton}_${sizeCls}`]: sizeCls,
        [`${antInputCls}-block`]: block,
        [`${antInputCls}-${sizeCls}`]: sizeCls,
      })}
      checked={checked}
      {...restProps}
    >
      <UiSpace size={spaceSize} align="center" className={styles.uiToggleButton__content}>
        {children}
      </UiSpace>
    </Radio.Button>
  );
};
