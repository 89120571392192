import React, { FC } from 'react';

import ImgPlaceholderSvg from '@vkph/ui/svg/img-placeholder.svg';

import { UiAvatarProps, UiAvatar } from '../../avatar';
import { UiIcon } from '../../icon';
import { UiTruncateMarkup } from '../../truncate-markup';
import styles from './UiTableCellAvatar.scss';

export interface UiTableCellAvatarProps extends UiAvatarProps {
  name: string;
}

export const UiTableCellAvatar: FC<UiTableCellAvatarProps> = (props) => {
  const { name, size, ...rest } = props;

  return (
    <div className={styles.uiTableCellAvatar}>
      <UiAvatar
        size={size}
        className={styles.uiTableCellAvatar__avatar}
        icon={<UiIcon component={ImgPlaceholderSvg} height={size} width={size} />}
        {...rest}
      />
      <UiTruncateMarkup truncate>{name}</UiTruncateMarkup>
    </div>
  );
};
