import { FC } from 'react';

import { UiCheckbox as Checkbox, UiCheckboxProps } from './UiCheckbox';
import { UiCheckboxGroup } from './group/UiCheckboxGroup';
import { UiCheckboxMenu } from './menu';

export * from './UiCheckbox';

export interface UiCheckboxComposition {
  Group: typeof UiCheckboxGroup;
  Menu: typeof UiCheckboxMenu;
}

export const UiCheckbox = Checkbox as FC<UiCheckboxProps> & UiCheckboxComposition;

UiCheckbox.Group = UiCheckboxGroup;
UiCheckbox.Menu = UiCheckboxMenu;
