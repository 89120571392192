import { List, ListProps } from 'antd';
import React from 'react';

import { UiPaginationProps } from '../pagination';
import { UiSpinner } from '../spinner';
import { UiListItem } from './item';

/** TODO обсудить нужен ли тут onClick и состояние selected */
export type UiListProps<T> = ListProps<T>;
export type UiListOnChangePagination = UiPaginationProps['onChange'];

export const UiList = <T,>(props: UiListProps<T>) => {
  const { loading } = props;

  return (
    <List
      {...props}
      loading={{ indicator: UiSpinner.Indicator, spinning: Boolean(loading), size: 'large' }}
    />
  );
};

UiList.Item = UiListItem;
