import { Upload } from 'antd';
import {
  UploadChangeParam as AntUploadChangeParam,
  UploadProps as AntUploadProps,
  RcFile as AntRcFile,
  UploadFile as AntUploadFile,
} from 'antd/es/upload';
import { UploadFileStatus, ItemRender } from 'antd/es/upload/interface';
import { UploadRequestOption as RcUploadRequestOption } from 'rc-upload/lib/interface';
import React, { FC } from 'react';

import { useModifyBeforeUpload } from './hooks';

export type UploadChangeParam = AntUploadChangeParam;
export type UiUploadFile = AntUploadFile;
export type UiUploadFileUid = AntUploadFile['uid'];
export type UiUploadFileStatus = UploadFileStatus;
export type UiUploadRequestOption = RcUploadRequestOption;
export type UiUploadOriginFile = AntRcFile;
export type UiUploadListItemRender = ItemRender;
export type UiUploadOnPickFilesHandler = (fileList: UiUploadOriginFile[]) => void;
type UiUploadBeforeUploadValueType = void | boolean | string | Blob | File;
export type UiUploadBeforeUploadHandler = (
  file: UiUploadOriginFile,
  fileList: UiUploadOriginFile[],
) => UiUploadBeforeUploadValueType | Promise<UiUploadBeforeUploadValueType>;
export interface UiUploadProps extends AntUploadProps {
  onPickFiles?: UiUploadOnPickFilesHandler;
}

export const UiUpload: FC<UiUploadProps> = (props) => {
  const { onPickFiles, beforeUpload, ...restProps } = props;
  const { onBeforeUpload } = useModifyBeforeUpload({ beforeUpload, onPickFiles });

  return <Upload beforeUpload={onBeforeUpload} {...restProps} />;
};
