import { FC } from 'react';

import { UiTag as Tag, UiTagProps } from './UiTag';
import { UiTagGroup } from './group/UiTagGroup';

export * from './UiTag';

export interface UiTagComponent extends FC<UiTagProps> {
  Group: typeof UiTagGroup;
}

export const UiTag = Tag as UiTagComponent;

UiTag.Group = UiTagGroup;
