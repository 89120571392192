import React, { PropsWithChildren, useMemo, useState, Key, ReactNode } from 'react';

import { useSpace } from '../../../../hooks';
import { useTheme, variables } from '../../../../providers/theme';
import { UiDnDActiveType, UiDnD, dndClosesCenterCollision } from '../../../dnd';
import { getDnDArrayMove, dndVerticalListSortingStrategy, snapDnDItemLeftToCursor } from '../../../dnd/utils';
import { UiSpace } from '../../../space';
import { UiTableProps } from '../../UiTable';
import { UiTableDnDCellOverlay } from '../cell-overlay/UiTableDnDCellOverlay';
import { TableDnDConfigProps } from '../useTableDnDConfig';

type UiTableDnDContextBodyProps<T extends object = Record<string, unknown>> = Pick<
  UiTableProps<T>,
  'columns' | 'dataSource'
> &
  Pick<TableDnDConfigProps<T>, 'onRowDragEnd'>;

export const UiTableDnDContextBody = <T extends Record<string, unknown>>(
  props: PropsWithChildren<UiTableDnDContextBodyProps<T>>,
) => {
  const { children, columns = [], dataSource = [], onRowDragEnd, ...restProps } = props;
  const [activeItem, setActiveItem] = useState<UiDnDActiveType | null>(null);
  const [{ variables: themeVariables }] = useTheme();
  const { spaceXL } = useSpace();

  const activeRow = useMemo(() => {
    const activeRowData = dataSource?.[activeItem?.data?.current?.sortable?.index];

    return columns.reduce<{ key: Key; value: ReactNode }[]>((acc, { key }) => {
      const rowData =
        key && typeof key === 'string' && activeRowData ? (activeRowData[key] as ReactNode) : undefined;

      if (rowData && key) {
        acc.push({
          key,
          value: rowData,
        });
      }

      return acc;
    }, []);
  }, [activeItem, columns]);

  return (
    <tbody {...restProps}>
      <UiDnD.Context<T[]>
        modifiers={[snapDnDItemLeftToCursor({ horizontalShift: spaceXL })]}
        collisionDetection={dndClosesCenterCollision}
        onDragStart={({ active }) => {
          setActiveItem(active);
        }}
        onDragEnd={(event) => {
          const { active, over } = event;

          if (over && active.id !== over?.id && dataSource && onRowDragEnd) {
            const activeIndex = active?.data?.current?.sortable?.index;
            const overIndex = over?.data?.current?.sortable?.index;

            if (typeof activeIndex === 'number' && typeof overIndex === 'number') {
              onRowDragEnd(getDnDArrayMove(dataSource, activeIndex, overIndex));
            }
          }

          setActiveItem(null);
        }}
      >
        <UiDnD.Sortable.Context
          items={dataSource.map((item) => item.key as string)}
          strategy={dndVerticalListSortingStrategy}
        >
          {children}
        </UiDnD.Sortable.Context>

        <UiDnD.Overlay>
          {activeRow && (
            <UiSpace
              size={0}
              style={{
                borderRadius: variables.themeSizes.borderRadiusLarge,
                backgroundColor: themeVariables.colorBrand,
              }}
            >
              {activeRow.map((item) => (
                <UiTableDnDCellOverlay key={item.key} grabIcon={false}>
                  {item.value}
                </UiTableDnDCellOverlay>
              ))}
            </UiSpace>
          )}
        </UiDnD.Overlay>
      </UiDnD.Context>
    </tbody>
  );
};
