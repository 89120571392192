import classNames from 'classnames';
import React, { FC, useMemo } from 'react';

import { UiFormListItemMoveActionType } from '../../form';
import { UiTruncateMarkup } from '../../truncate-markup';
import { UiMenuItemTypes, UiMenu, UiMenuItemType, UiMenuProps, UiSubMenuType } from '../UiMenu';
import styles from './UiMenuCollapsible.scss';
import { UiMenuCollapsibleControls } from './collapsible-controls/UiMenuCollapsibleControls';
import { UiMenuCollapsibleSizes } from './types';

export type UiMenuCollapsibleProps = UiMenuProps & {
  className?: string;
  size?: UiMenuCollapsibleSizes;
  onMove?: UiFormListItemMoveActionType;
};

// TODO:: Убрать стрелки - добавить вместо них D&D B2BCORE-3282
export const UiMenuCollapsible: FC<UiMenuCollapsibleProps> = (props) => {
  const { items, onMove, className, size = UiMenuCollapsibleSizes.Small, ...rest } = props;

  const isSubMenuItemType = (menuItem: Exclude<UiMenuItemTypes, null>): menuItem is UiSubMenuType =>
    'children' in menuItem;
  const isMenuItemType = (menuItem: Exclude<UiMenuItemTypes, null>): menuItem is UiMenuItemType =>
    !('type' in menuItem);

  const normalizeItems = (optionsArr: UiMenuItemTypes[]): UiMenuItemTypes[] =>
    optionsArr.map((option, index) => {
      if (!option) {
        return null;
      }

      if (isSubMenuItemType(option)) {
        return {
          ...option,
          children: option.children && normalizeItems(option.children),
          label: (
            <>
              <UiTruncateMarkup truncate lines={1}>
                {option.label}
              </UiTruncateMarkup>
            </>
          ),
        };
      }

      if (isMenuItemType(option)) {
        return {
          ...option,
          label: (
            <>
              <UiTruncateMarkup truncate lines={1}>
                {option.label}
              </UiTruncateMarkup>
              {onMove && <UiMenuCollapsibleControls index={index} items={optionsArr} onMove={onMove} />}
            </>
          ),
        };
      }

      return option;
    });

  const options = useMemo(() => (items ? normalizeItems(items) : items), [items]);

  return (
    <UiMenu
      {...rest}
      mode="inline"
      inlineIndent={20}
      expandIcon={() => null}
      className={classNames(styles.uiMenuCollapsible, `${styles.uiMenuCollapsible}_${size}`, className)}
      items={options}
    />
  );
};
