import { ModalFuncProps } from 'antd';
import { HookAPI } from 'antd/es/modal/useModal';
import { useEffect, useRef } from 'react';

import { useApp } from './useApp';

// TODO: Заменить на useBlocker из react-router-dom https://jira.vk.team/browse/B2BCORE-11050
export const useFormBlocker = <T extends HTMLElement>(
  isBlocked: boolean,
  modalType: keyof HookAPI = 'confirm',
  modalProps: ModalFuncProps = {},
) => {
  const ref = useRef<T>(null);
  const { modal } = useApp();

  useEffect(() => {
    const options: EventListenerOptions = { capture: true };

    const onClick = (event: MouseEvent) => {
      const isHTMLElement = event.target instanceof HTMLElement;

      if (!isHTMLElement || ref.current?.contains(event.target)) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      window.removeEventListener('click', onClick, options);

      const targetElement = event.target;
      const { onOk, onCancel, ...rest } = modalProps;

      modal[modalType]({
        icon: null,
        title: 'Отменить и выйти?',
        okText: 'Выйти',
        cancelText: 'Отмена',
        onOk: (...args) => {
          onOk?.(args);
          targetElement.click();
          window.addEventListener('click', onClick, options);
        },
        onCancel: (...args) => {
          onCancel?.(args);
          window.addEventListener('click', onClick, options);
        },
        ...rest,
      });
    };

    if (isBlocked) {
      window.addEventListener('click', onClick, options);
    }

    return () => window.removeEventListener('click', onClick, options);
  }, [isBlocked]);

  return ref;
};
