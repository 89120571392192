import { TreeSelect, TreeSelectProps } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';

import ClearSVG from '@vkph/ui/svg/clear.svg';

import DropDownSvg from '../../svg/drop-down.svg';
import { UiButton } from '../button';
import { UiIcon } from '../icon';
import styles from './UiTreeSelect.scss';

export type UiTreeSelectProps = TreeSelectProps;

const { SHOW_ALL, SHOW_CHILD, SHOW_PARENT } = TreeSelect;

type UiTreeSelectComposition = {
  UiTreeNode: typeof TreeSelect.TreeNode;
  SHOW_ALL: typeof SHOW_ALL;
  SHOW_CHILD: typeof SHOW_CHILD;
  SHOW_PARENT: typeof SHOW_PARENT;
};

export const UiTreeSelect: FC<UiTreeSelectProps> & UiTreeSelectComposition = (props) => {
  const { switcherIcon, suffixIcon, className, popupClassName, treeCheckable, ...rest } = props;
  const SuffixIcon = suffixIcon || <DropDownSvg />;
  const SwitcherIcon = switcherIcon || <DropDownSvg />;

  const RemoveButton = (
    <UiButton
      size="middle"
      type="link-secondary"
      icon={<UiIcon component={ClearSVG} width={20} height={20} />}
    />
  );

  return (
    <TreeSelect
      className={classNames(className, treeCheckable && styles.uiTreeSelect_treeCheckable)}
      popupClassName={classNames(popupClassName, treeCheckable && styles.uiTreeSelect__popup_treeCheckable)}
      suffixIcon={SuffixIcon}
      switcherIcon={SwitcherIcon}
      treeCheckable={treeCheckable}
      removeIcon={RemoveButton}
      {...rest}
    />
  );
};

UiTreeSelect.UiTreeNode = TreeSelect.TreeNode;
UiTreeSelect.SHOW_ALL = SHOW_ALL;
UiTreeSelect.SHOW_CHILD = SHOW_CHILD;
UiTreeSelect.SHOW_PARENT = SHOW_PARENT;
