import React, { FC, ReactNode } from 'react';

import { useSpace } from '../../../hooks';
import { getModalStepsForSingleTitle } from '../../../utils';
import { stringMatchesRule } from '../../../utils/validation';
import { UiButton } from '../../button';
import { UiFlex } from '../../flex';
import { UiForm } from '../../form';
import { UiInput, UiInputProps } from '../../input';
import { UiModal, UiModalProps } from '../modal';

export interface ModalDeleteProps extends Omit<UiModalProps, 'type'> {
  title: string;
  match: string;
  placeholder?: string;
  description: ReactNode;
  textOk?: string;
  textCancel?: string;
  matchErrorMessage?: string;
  onClose: () => void;
  onSuccess: () => void;
  inputProps?: UiInputProps;
}

export const ModalDelete: FC<ModalDeleteProps> = (props) => {
  const {
    description,
    title,
    match,
    textOk = 'Удалить',
    textCancel = 'Отмена',
    inputProps,
    onClose,
    onSuccess,
    matchErrorMessage = 'Название не совпадает',
  } = props;
  const { spaceXL } = useSpace();
  const [form] = UiForm.useForm();

  return (
    <>
      <UiModal.Header>
        <UiModal.Header.Title
          style={{ paddingBottom: 0 }}
          noCloseIcon
          steps={getModalStepsForSingleTitle(title)}
        />
      </UiModal.Header>
      <UiForm form={form}>
        <UiModal.Content basePadding>
          <UiForm.Item>{description}</UiForm.Item>
          <UiForm.Item name="inputValue" rules={[stringMatchesRule(match, matchErrorMessage)]}>
            <UiInput size="large" {...inputProps} />
          </UiForm.Item>
          <UiFlex gap={spaceXL}>
            <UiForm.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const isDisabled = match !== getFieldValue('inputValue');

                return (
                  <UiButton
                    size="large"
                    type="danger"
                    block
                    label={textOk}
                    onClick={onSuccess}
                    disabled={isDisabled}
                  />
                );
              }}
            </UiForm.Item>
            <UiButton size="large" type="tertiary" block label={textCancel} onClick={onClose} />
          </UiFlex>
        </UiModal.Content>
      </UiForm>
    </>
  );
};
