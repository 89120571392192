import { ConfigProvider } from 'antd';
import React, { useState, useRef } from 'react';

export const useImageCustomContentPreview = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);

  const { getPrefixCls } = React.useContext(ConfigProvider.ConfigContext);
  const imagePreviewOperationsWrapperSelector = getPrefixCls('image-preview-operations-wrapper');
  const fadeLeaveActiveSelector = getPrefixCls('fade-leave-active');

  const getContainer = () => {
    if (
      !ref.current?.getElementsByClassName(fadeLeaveActiveSelector)?.length &&
      ref.current?.getElementsByClassName(imagePreviewOperationsWrapperSelector)?.length
    ) {
      setContainerElement(
        ref.current.getElementsByClassName(imagePreviewOperationsWrapperSelector)[0] as HTMLDivElement,
      );
    }

    return ref.current as HTMLDivElement;
  };

  return { ref, getContainer, containerElement };
};
