import { DragOverlay, UniqueIdentifier } from '@dnd-kit/core';

import { UiDnDContext } from './context/UiDnDContext';
import { UiDnDSortable } from './sortable/UiDnDSortable';

export {
  MouseSensor as DnDMouseSensor,
  TouchSensor as DnDTouchSensor,
  useSensor as useDnDSensor,
  useSensors as useDnDSensors,
  closestCenter as dndClosesCenterCollision,
} from '@dnd-kit/core';

export type { SortableContextProps as UiDnDSortableContextProps } from '@dnd-kit/sortable';
export type { DragOverlayProps as UiDnDOverlayProps } from '@dnd-kit/core';

export * from './hooks';
export type {
  UiDnDData,
  UiDnDActiveType,
  UiDnDOverType,
  UiDnDDragStartEvent,
  UiDnDDragMoveEvent,
  UiDnDDragOverEvent,
  UiDnDDragEndEvent,
  UiDnDDragCancelEvent,
  UiDnDContextProps,
} from './context/UiDnDContext';
export type UiDnDUniqueIdentifier = UniqueIdentifier;
export type { UiDnDSortableItemProps } from './sortable/item/UiDnDSortableItem';
export type { UiDnDSortableDragIconProps } from './sortable/drag-icon/UiDnDSortableDragIcon';
export type { UiDnDSortableDragItemProps } from './sortable/drag-item/UiDnDSortableDragItem';

export const UiDnD = {
  Context: UiDnDContext,
  Sortable: UiDnDSortable,
  Overlay: DragOverlay,
};
