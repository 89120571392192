import { Tabs as AntTabs } from 'antd';
import { TabsProps, TabPaneProps } from 'antd/es/tabs';
import classNames from 'classnames';
import { Tab } from 'rc-tabs/lib/interface';
import React, { FC } from 'react';

import MeatballSvg from '@vkph/ui/svg/meatball.svg';

import { UiIcon } from '../icon';
import styles from './UiTabs.scss';

const ccn = classNames.bind(styles);

type UiTabComponent = FC<TabPaneProps>;

interface UiTabsComposition {
  Tab: UiTabComponent;
}

export interface UiTabsProps extends TabsProps {
  full?: boolean;
}

type UiTabsComponent = FC<UiTabsProps> & UiTabsComposition;

export type UiTabItem<Key extends string> = Omit<Tab, 'key'> & { key: Key };

export const UiTab: UiTabComponent = (props) => <AntTabs.TabPane {...props} />;

export const UiTabs: UiTabsComponent = (props) => {
  const { className, full, ...restProps } = props;

  return (
    <AntTabs
      className={ccn(styles.tabs, className, {
        [styles.tabs_full]: full,
      })}
      moreIcon={<UiIcon width={20} height={20} component={MeatballSvg} />}
      {...restProps}
    />
  );
};

UiTabs.Tab = UiTab;
