import { Grid, SiderProps } from 'antd';
import React, { CSSProperties, FC, useMemo } from 'react';

import { useSpace } from '../../../../hooks';
import { variables } from '../../../../providers/theme';
import { UiFlex } from '../../../flex';
import { UiCol, UiColProps } from '../../../grid';

type SiderSizes = Pick<UiColProps, 'lg' | 'xl' | 'xxl' | 'sm' | 'md' | 'xs'> | undefined;
export type LayoutSiderContentProps = UiColProps & Pick<SiderProps, 'breakpoint'>;

export const LayoutSiderContent: FC<LayoutSiderContentProps> = (props) => {
  const { children, breakpoint = 'lg', flex, style, ...restProps } = props;
  const { span, xl, xxl, xs, lg, sm, md } = restProps;
  const sizes = [xl, xxl, xs, lg, sm, md];
  const hasSizes = sizes.some((size) => size !== undefined);
  const breakpoints = Grid.useBreakpoint();
  const { spaceL } = useSpace();

  const defaultSizes = useMemo<SiderSizes>(() => {
    if (span || hasSizes) {
      return undefined;
    }

    return {
      xxl: 6,
      xl: 7,
      lg: 8,
    };
  }, [span]);

  const mergedStyle: CSSProperties = {
    maxWidth: variables.layoutSizes.siderMaxWidth,
    ...style,
  };

  return (
    <UiCol {...restProps} {...defaultSizes} style={mergedStyle} hidden={!breakpoints[breakpoint]}>
      <UiFlex gap={spaceL} vertical>
        {children}
      </UiFlex>
    </UiCol>
  );
};
