import generatePicker from 'antd/es/date-picker/generatePicker';
import rusLocale from 'antd/lib/date-picker/locale/ru_RU';
import { isValid, parse, format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

rusLocale.lang.locale = 'ru';

/**
 * @note: 🚩 Используй только локально в пределах директории date-picker/*
 */
export const DateFnsPicker = generatePicker<Date>({
  ...dateFnsGenerateConfig,
  locale: {
    ...dateFnsGenerateConfig.locale,
    ...rusLocale,
    parse: (_, text, formats) => {
      const validFormat = formats.some((currentFormat) => {
        const parsedDate = parse(text, currentFormat, new Date(), {
          locale: ru,
        });

        return isValid(parsedDate) && format(parsedDate, currentFormat) === text;
      });

      if (validFormat) {
        return parse(text, formats[0], new Date(), {
          locale: ru,
        });
      }

      return null;
    },
  },
});
