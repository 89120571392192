import React, { FC } from 'react';

import { UiList } from '../list';
import { UiUploadFile, UiUploadFileUid } from '../upload';
import { UiUploadFileListItem, UiUploadFileType } from './item/UiUploadFileListItem';

export type UiUploadFileListProps = {
  files: UiUploadFile[];
  onDelete?: (uid: UiUploadFileUid) => void;
  onRefetch?: (uid: UiUploadFileUid) => void;
  type?: UiUploadFileType;
};

export type UiUploadFileListComposition = {
  Item: typeof UiUploadFileListItem;
};

export type UiUploadFileListComponent = UiUploadFileListComposition & FC<UiUploadFileListProps>;

export const UiUploadFileList: UiUploadFileListComponent = (props) => {
  const { files, onDelete, onRefetch, type = UiUploadFileType.Simple } = props;

  return (
    <UiList<UiUploadFile>
      dataSource={files}
      split={false}
      renderItem={(file) => (
        <UiList.Item noStyle>
          <UiUploadFileList.Item type={type} file={file} onDelete={onDelete} onRefetch={onRefetch} />
        </UiList.Item>
      )}
    />
  );
};

UiUploadFileList.Item = UiUploadFileListItem;
