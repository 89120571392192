import { Rule } from '../../components/form';

/**
 * @example match: "example", value: "example" => resolve
 * @example  match: "exampleString", value: "differentString" => reject
 */
export const stringMatchesRule = (match: string, matchErrorMessage = 'Значение не совпадает'): Rule => ({
  validator: (_, value: string) => {
    const isMatch = match.startsWith(value);

    return !isMatch ? Promise.reject() : Promise.resolve();
  },
  message: matchErrorMessage,
});
