import { FC } from 'react';

import { UiSwitch as Switch, UiSwitchProps } from './UiSwitch';
import { UiSwitchGroup } from './group';

export * from './UiSwitch';
export type { UiSwitchGroupProps } from './group';

export interface UiSwitchComponent extends FC<UiSwitchProps> {
  Group: typeof UiSwitchGroup;
}

export const UiSwitch = Switch as UiSwitchComponent;

UiSwitch.Group = UiSwitchGroup;
