import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import isBoolean from 'lodash/isBoolean';
import React, { FC, useContext } from 'react';

import CalendarSvg from '@vkph/ui/svg/calendar.svg';

import { UiConfigContext } from '../config-provider';
import { UiDatePickerClearIcon } from './clear-icon/UiDatePickerClearIcon';
import { DateFnsPicker } from './date-fns-picker/DateFnsPicker';

export type UiDatePickerFormatType = 'date' | 'dateTime' | 'dateYear';
export type UiDatePickerFormatTypeProps = {
  formatType?: UiDatePickerFormatType;
};
export interface UiDatePickerProps extends PickerProps, UiDatePickerFormatTypeProps {}

export const datePickerFormats: Record<UiDatePickerFormatType, string[]> = {
  date: ['dd.MM.yyyy', 'd.MM.yyyy', 'dd.M.yyyy'],
  dateTime: ['dd.MM.yyyy HH:mm', 'd.MM.yyyy HH:mm', 'dd.M.yyyy HH:mm'],
  dateYear: ['yyyy'],
};

const datePickerPlaceholders: Record<UiDatePickerFormatType, PickerProps['placeholder']> = {
  date: '––.––.––––',
  dateTime: '––.––.–––– ––:––',
  dateYear: '––––',
};

const showTimeFormatDefault = { format: 'HH:mm' };

export const UiDatePicker: FC<UiDatePickerProps> = (props) => {
  const { formatType = 'date', showTime, ...restProps } = props;
  const { getPopupContainer } = useContext(UiConfigContext);

  const showTimeFormat = showTime && isBoolean(showTime) ? showTimeFormatDefault : showTime;

  return (
    <DateFnsPicker
      showTime={showTimeFormat}
      suffixIcon={<CalendarSvg />}
      format={datePickerFormats[formatType]}
      placeholder={datePickerPlaceholders[formatType]}
      allowClear={{ clearIcon: UiDatePickerClearIcon }}
      getPopupContainer={() => getPopupContainer?.() || document.body}
      {...restProps}
    />
  );
};
