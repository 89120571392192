import { FilterDropdownProps } from 'antd/es/table/interface';
import React, { FC, PropsWithChildren } from 'react';

import { usePaddingStyle, useSpace } from '../../../../hooks';
import { UiButton } from '../../../button';
import { UiCheckbox } from '../../../checkbox';
import { UiDivider } from '../../../divider';
import { UiFlex } from '../../../flex';
import { UiList } from '../../../list';
import { UiRadio, UiRadioChangeEvent } from '../../../radio';
import { UiTableFilterDropdownButton } from './UiTableFilterButton';
import styles from './UiTableFilterDropdown.scss';

type UiTableFilterDropdownComposition = {
  Button: typeof UiTableFilterDropdownButton;
};

export interface UiTableFilterDropdownProps {
  dropdownProps: FilterDropdownProps;
  multiple?: boolean;
}

export const UiTableFilterDropdown: FC<UiTableFilterDropdownProps> & UiTableFilterDropdownComposition = (
  props,
) => {
  const { multiple = true, dropdownProps } = props;
  const { selectedKeys, filters, setSelectedKeys, confirm, clearFilters } = dropdownProps;

  const { spaceM, spaceXL } = useSpace();
  const buttonsWrapperPadding = usePaddingStyle([spaceXL]);

  const handleRadioChange = (e: UiRadioChangeEvent) => {
    setSelectedKeys([e.target.value]);
  };

  const handleReset = () => {
    clearFilters?.();
    confirm({ closeDropdown: true });
  };

  const ItemsGroupWrapper: FC<PropsWithChildren> = (wrapperProps) => {
    const { children } = wrapperProps;

    if (multiple) {
      return (
        <UiCheckbox.Group value={selectedKeys} onChange={setSelectedKeys}>
          {children}
        </UiCheckbox.Group>
      );
    }

    return (
      <UiRadio.Group value={selectedKeys[0]} onChange={handleRadioChange}>
        {children}
      </UiRadio.Group>
    );
  };

  const ItemWrapper = multiple ? UiCheckbox : UiRadio;

  return (
    <UiFlex vertical className={styles.filterDropdown}>
      <UiFlex className={styles.filterDropdown__listWrapper}>
        <ItemsGroupWrapper>
          <UiList
            split={false}
            dataSource={filters}
            renderItem={(item) => (
              <UiList.Item hoverable>
                <ItemWrapper value={item.value}>{item.text}</ItemWrapper>
              </UiList.Item>
            )}
          />
        </ItemsGroupWrapper>
      </UiFlex>
      <UiDivider emptyMargin />
      <UiFlex style={buttonsWrapperPadding} gap={spaceM}>
        <UiButton type="primary" size="small" label="Применить" onClick={() => confirm()} block />
        <UiButton type="tertiary" size="small" label="Сбросить" onClick={handleReset} block />
      </UiFlex>
    </UiFlex>
  );
};

UiTableFilterDropdown.Button = UiTableFilterDropdownButton;
