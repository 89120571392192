import React, { FC } from 'react';

import AttachSVG from '@vkph/ui/svg/attach.svg';
import ClearSVG from '@vkph/ui/svg/clear.svg';
import ErrorSvg from '@vkph/ui/svg/error.svg';
import RefreshSVG from '@vkph/ui/svg/refresh.svg';

import type { UiUploadFileListProps } from '..';
import { useSpace } from '../../../hooks';
import { variables } from '../../../providers/theme';
import { getFormattedFileSize } from '../../../utils';
import { UiButton } from '../../button';
import { UiFile } from '../../file';
import { UiCol, UiRow } from '../../grid';
import { UiIcon } from '../../icon';
import { UiSpace } from '../../space';
import { UiSpinner } from '../../spinner';
import { UiTruncateMarkup } from '../../truncate-markup';
import { UiTypography, UiTypographyTextTypes } from '../../typography';
import { UiUploadFile, UiUploadFileStatusType } from '../../upload';
import styles from './UiUploadFileListItem.scss';

export enum UiUploadFileType {
  Simple = 'simple',
  Verbose = 'verbose',
}

interface UiUploadFileListItemProps extends Omit<UiUploadFileListProps, 'files'> {
  file: UiUploadFile;
}

export const UiUploadFileListItem: FC<UiUploadFileListItemProps> = (props) => {
  const { file, onDelete, onRefetch, type = UiUploadFileType.Simple } = props;
  const { status, name: fileName, uid, size } = file;
  const fileSize = getFormattedFileSize(size);
  const { spaceXS } = useSpace();
  const isUploadingStatus = (statusType: UiUploadFileStatusType) => status === statusType;

  const isUploading = isUploadingStatus(UiUploadFileStatusType.Uploading);
  const isError = isUploadingStatus(UiUploadFileStatusType.Error);
  const isUploaded =
    isUploadingStatus(UiUploadFileStatusType.Done) || isUploadingStatus(UiUploadFileStatusType.Success);

  return (
    <UiSpace direction="vertical" full size={spaceXS} className={styles.uiUploadFileListItem}>
      <UiRow justify="space-between" align="middle" wrap={false}>
        <UiCol span={20}>
          {type === UiUploadFileType.Simple && (
            <>
              {isUploading && (
                <UiSpace size={spaceXS}>
                  <UiSpinner size="default" />
                  <UiTypography.Text>{fileName}</UiTypography.Text>
                </UiSpace>
              )}
              {isError && (
                <UiRow justify="space-between" wrap={false} style={{ width: '100%' }}>
                  <UiIcon.Label
                    type={UiTypographyTextTypes.Primary}
                    component={ErrorSvg}
                    color={variables.themePalette.colorNegative}
                  >
                    <UiTruncateMarkup truncate>{fileName}</UiTruncateMarkup>
                  </UiIcon.Label>
                  <UiButton
                    type="link"
                    icon={<UiIcon width={20} height={20} component={RefreshSVG} />}
                    style={{ marginRight: spaceXS }}
                    onClick={() => onRefetch?.(file.uid)}
                    label="Повторить"
                  />
                </UiRow>
              )}

              {isUploaded && (
                <UiIcon.Label
                  type={UiTypographyTextTypes.Primary}
                  component={AttachSVG}
                  color={variables.themePalette.colorIcon}
                >
                  <UiTruncateMarkup truncate>{fileName}</UiTruncateMarkup>
                </UiIcon.Label>
              )}
            </>
          )}
          {type === UiUploadFileType.Verbose && (
            <UiFile
              title={
                <UiTypography.Text strong>
                  {isError && `Ошибка загрузки: `}
                  {fileName}
                </UiTypography.Text>
              }
              subtitle={fileSize}
              fileName={fileName}
              loading={isUploadingStatus(UiUploadFileStatusType.Uploading)}
            />
          )}
        </UiCol>
        <UiCol>
          {onDelete && (
            <UiButton
              size="small"
              type="link-secondary"
              onClick={() => onDelete(uid)}
              icon={<UiIcon component={ClearSVG} width={20} height={20} />}
            />
          )}
        </UiCol>
      </UiRow>
      {type === UiUploadFileType.Simple && file.error && (
        <UiTypography.Footnote style={{ color: variables.themePalette.colorNegative }}>
          <UiTruncateMarkup truncate lines={2}>
            {file.error}
          </UiTruncateMarkup>
        </UiTypography.Footnote>
      )}
    </UiSpace>
  );
};
