import { Select as AntSelect, SelectProps, AutoCompleteProps } from 'antd';
import { SelectValue, LabeledValue, RefSelectProps } from 'antd/es/select';
import classNames from 'classnames';
import { BaseSelectPrivateProps, BaseSelectRef } from 'rc-select/es/BaseSelect';
import { BaseOptionType, DefaultOptionType } from 'rc-select/es/Select';
import React, { FC, PropsWithChildren, ReactElement, ReactNode, Ref, useContext } from 'react';

import DropDownSvg from '@vkph/ui/svg/drop-down.svg';

import { UiConfigContext } from '../config-provider';
import { UiTruncateMarkup } from '../truncate-markup';
import styles from './UiSelect.scss';

export type UiBaseSelectRef = BaseSelectRef;
export type UiRefSelectProps = RefSelectProps;
export type UiOptionItem = { value: string; label: string };
export type UiSelectBaseOptionType = BaseOptionType;
export interface UiSelectDefaultOption<T = string> extends Omit<DefaultOptionType, 'value'> {
  value: T;
}
export type UiOptionData = UiSelectDefaultOption;
export interface UiOptionDataExtended<T = unknown> extends UiSelectDefaultOption {
  data?: T;
}
export type UiLabeledValue = LabeledValue;
export type UiSelectValue = SelectValue;

// @ts-expect-error TODO: Pick<BaseSelectPrivateProps, 'maxLength'> - хак, но правильнее работать через getInputElement, но в antd этого пропса нет
export type UiSelectMaxLengthProps = Pick<BaseSelectPrivateProps, 'maxLength'>;
export interface UiSelectProps<
  VT,
  OT extends UiSelectBaseOptionType | UiSelectDefaultOption = UiSelectDefaultOption,
> extends Omit<SelectProps<VT, OT>, 'showArrow'> {
  type?: 'text';
  prefix?: ReactNode;
}

export const getPopupContainerParentElement: AutoCompleteProps['getPopupContainer'] = (trigger) =>
  trigger.parentElement;

const InternalUiSelect = <
  OptionType extends UiSelectBaseOptionType | UiSelectDefaultOption = UiSelectDefaultOption,
>(
  props: UiSelectProps<OptionType>,
  ref: Ref<BaseSelectRef>,
) => {
  const { prefix, type, suffixIcon, className, popupClassName, mode, placeholder, labelRender, ...rest } =
    props;
  const SuffixIcon = suffixIcon !== undefined ? suffixIcon : <DropDownSvg />;
  const isTextType = type === 'text';
  const isMultipleMode = mode === 'multiple';
  const isTagsMode = mode === 'tags';

  const isMultiSelect = isTagsMode || isMultipleMode;
  const hasPrefix = !isMultiSelect && prefix;

  const { getPrefixCls } = useContext(UiConfigContext);

  const classNamesStyles = classNames(className, {
    [styles.uiSelect__text]: isTextType,
    [styles.multiSelect]: isMultipleMode,
  });
  const popupClassNameStyles = classNames(popupClassName, {
    [styles.uiSelect__textOptions]: isTextType,
    [styles.multiSelect__dropdown]: isMultipleMode,
  });

  const SelectPrefix: FC<
    PropsWithChildren<{
      prefix?: ReactNode;
    }>
  > = (prefixProps) => {
    const { prefix: internalPrefix, children } = prefixProps;
    const prefixCls =
      typeof prefix === 'string' || typeof prefix === 'number'
        ? getPrefixCls('select-prefix-text')
        : getPrefixCls('select-prefix');
    const label =
      typeof children === 'string' || typeof children === 'number' ? (
        <UiTruncateMarkup truncate>{children}</UiTruncateMarkup>
      ) : (
        children
      );

    return (
      <>
        <span className={prefixCls}>{internalPrefix}</span>
        {label}
      </>
    );
  };

  const renderLabel: UiSelectProps<OptionType>['labelRender'] = (params) => {
    const label = labelRender ? labelRender(params) : params.label;

    return hasPrefix ? <SelectPrefix prefix={prefix}>{label}</SelectPrefix> : label;
  };

  const selectPlaceholder = hasPrefix ? (
    <SelectPrefix prefix={prefix}>{placeholder}</SelectPrefix>
  ) : (
    placeholder
  );

  return (
    <AntSelect
      {...rest}
      ref={ref}
      mode={mode}
      suffixIcon={SuffixIcon}
      className={classNamesStyles}
      popupClassName={popupClassNameStyles}
      placeholder={selectPlaceholder}
      labelRender={renderLabel}
    />
  );
};

export const UiSelect = React.forwardRef(InternalUiSelect) as unknown as (<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ValueType = any,
  OptionType extends UiSelectBaseOptionType | UiSelectDefaultOption = UiSelectDefaultOption,
>(
  props: PropsWithChildren<Omit<UiSelectProps<ValueType, OptionType>, 'dropdownMatchSelectWidth'>> & {
    ref?: Ref<UiBaseSelectRef>;
  },
) => ReactElement) & {
  Option: typeof AntSelect.Option;
  OptGroup: typeof AntSelect.OptGroup;
};

UiSelect.Option = AntSelect.Option;
UiSelect.OptGroup = AntSelect.OptGroup;
