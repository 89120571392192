import { Avatar } from 'antd';
import { GroupProps } from 'antd/lib/avatar/group';
import classNames from 'classnames';
import React, { Children, FC, PropsWithChildren, ReactNode } from 'react';

import { UiTypography } from '../../typography';
import { UiAvatar, AvatarSize as UiAvatarAnySize, getNumberSizeAvatar } from '../UiAvatar';
import styles from './UiAvatarGroup.scss';

export interface UiAvatarGroupProps
  extends Omit<GroupProps, 'size' | 'maxCount' | 'maxPopoverPlacement' | 'maxPopoverTrigger' | 'maxStyle'> {
  maxIcon?: ReactNode;
  onClickSuffix?: () => void;
  bordered?: boolean;
  size?: UiAvatarAnySize;
}

export const UiAvatarGroup: FC<PropsWithChildren<UiAvatarGroupProps>> = (props) => {
  const {
    children,
    className,
    maxIcon,
    onClickSuffix,
    bordered = true,
    size: sizeProps = 'small',
    max,
    ...restProps
  } = props;
  const childrenCount = Children.count(children);
  const size = getNumberSizeAvatar(sizeProps);

  const AvatarContainer: FC<PropsWithChildren> = (containerProps) => {
    const { children: node } = containerProps;

    return (
      <Avatar.Group
        size={size}
        max={{
          count: maxIcon || onClickSuffix ? undefined : max?.count,
          popover: { placement: 'bottom', trigger: 'click' },
          ...max,
        }}
        className={classNames(
          styles.uiAvatarGroup,
          {
            [styles.uiAvatarGroup_bordered]: bordered,
          },
          className,
        )}
        {...restProps}
      >
        {node}
      </Avatar.Group>
    );
  };

  if (max?.count && max.count < childrenCount && (maxIcon || onClickSuffix)) {
    const childrenShow = Children.toArray(children).slice(0, max.count);

    return (
      <AvatarContainer>
        {childrenShow}
        <UiTypography.Link onClick={onClickSuffix}>
          {maxIcon && <UiAvatar size={size} icon={maxIcon} className={styles.uiAvatarGroup_withIcon} />}
          {!maxIcon && <Avatar size={size}>{`+${childrenCount - max.count}`}</Avatar>}
        </UiTypography.Link>
      </AvatarContainer>
    );
  }

  return <AvatarContainer>{children}</AvatarContainer>;
};
