import {
  UseDraggableArguments,
  UseDroppableArguments,
  useDndContext as useOriginalDndContext,
  useDraggable as useOriginalDraggable,
  useDroppable as useOriginalDroppable,
  type UniqueIdentifier,
} from '@dnd-kit/core';
import { UseSortableArguments, useSortable as useOriginalSortable } from '@dnd-kit/sortable';

import { UiDnDActiveType, UiDnDData, UiDnDOverType } from './context/UiDnDContext';

interface UseDnDContext<T> extends Omit<ReturnType<typeof useOriginalDndContext>, 'active' | 'over'> {
  active: UiDnDActiveType<T> | null;
  over: UiDnDOverType<T> | null;
}
export const useDnDContext = <T>() => useOriginalDndContext() as UseDnDContext<T>;

export type UiDnDSortableDragUniqueId = UniqueIdentifier;
type UseDnDHookDataProps<T> = {
  data?: UiDnDData<T>;
};
export type UseDnDSortable = ReturnType<typeof useOriginalSortable>;
export interface UseDnDSortableProps<T> extends Omit<UseSortableArguments, 'data'>, UseDnDHookDataProps<T> {}
export const useDnDSortable = <T>(props: UseDnDSortableProps<T>): UseDnDSortable =>
  useOriginalSortable(props);

export type UseDnDDroppable = ReturnType<typeof useOriginalDroppable>;
interface UseDnDDroppableProps<T> extends Omit<UseDroppableArguments, 'data'>, UseDnDHookDataProps<T> {}
export const useDnDDroppable = <T>(props: UseDnDDroppableProps<T>): UseDnDDroppable =>
  useOriginalDroppable(props);

export type UseDnDDraggable = ReturnType<typeof useOriginalDraggable>;
interface UseDnDDraggableProps<T> extends Omit<UseDraggableArguments, 'data'>, UseDnDHookDataProps<T> {}
export const useDnDDraggable = <T>(props: UseDnDDraggableProps<T>): UseDnDDraggable =>
  useOriginalDraggable(props);
