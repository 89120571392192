import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import { useSpace } from '../../hooks';
import ArrowLeft from '../../svg/arrow-left.svg';
import { UiButton } from '../button';
import { UiFlex } from '../flex';
import { UiIcon } from '../icon';
import { UiTruncateMarkup } from '../truncate-markup';
import { UiTypography } from '../typography';
import styles from './UiNavbar.scss';

export interface UiNavbarProps {
  extra?: ReactNode;
  backIcon?: ReactNode;
  title?: ReactNode;
  bordered?: boolean;
  onBack?: () => void;
}

const defaultBackIcon = <UiIcon component={ArrowLeft} width={20} height={20} />;

export const UiNavbar: FC<UiNavbarProps> = (props) => {
  const { backIcon = defaultBackIcon, extra, bordered = true, title, onBack } = props;
  const { spaceM } = useSpace();

  const isTitleString = typeof title === 'string';

  return (
    <UiFlex
      justify="space-between"
      align="center"
      gap={spaceM}
      className={classNames(styles.uiNavbar, {
        [styles.uiNavbar_bordered]: bordered,
        [styles.uiNavbar_noExtra]: !extra && backIcon && isTitleString,
        [styles.uiNavbar_noBackIcon]: !backIcon && extra && isTitleString,
      })}
    >
      {backIcon && (
        <div className={classNames(styles.uiNavbar__back, !isTitleString && styles.uiNavbar__back_small)}>
          <UiButton type="link-secondary" icon={backIcon} onClick={onBack} />
        </div>
      )}
      <div className={classNames(styles.uiNavbar__content, isTitleString && styles.uiNavbar__content_string)}>
        {isTitleString && (
          <UiTypography.Title level={3} style={{ textAlign: 'center', margin: 0 }}>
            <UiTruncateMarkup truncate lines={1}>
              {title}
            </UiTruncateMarkup>
          </UiTypography.Title>
        )}
        {!isTitleString && title}
      </div>
      {extra && (
        <UiFlex
          justify="flex-end"
          className={classNames(styles.uiNavbar__extra, !isTitleString && styles.uiNavbar__extra_small)}
        >
          {extra}
        </UiFlex>
      )}
    </UiFlex>
  );
};
