import { TinyColor } from '@ctrl/tinycolor';
import classNames from 'classnames';
import React, { FC } from 'react';

import { UiButton, UiButtonProps } from '../../button';
import { UiSpace } from '../../space';
import { UiTypography, UiTypographyTextTypes } from '../../typography';
import styles from './UiColorPickerButton.scss';

export interface UiColorPickerButtonProps extends Omit<UiButtonProps, 'value'> {
  value: string;
}

export const UiColorPickerButton: FC<UiColorPickerButtonProps> = (props) => {
  const { className, style, value, ...btnProps } = props;

  const tinyColor = new TinyColor(value);
  const isTextOnDarkBackground = tinyColor.isDark() && tinyColor.getAlpha() >= 0.5;
  const type = isTextOnDarkBackground ? UiTypographyTextTypes.Invert : UiTypographyTextTypes.Primary;

  const hex = tinyColor.toHex().toUpperCase();
  const alpha = Math.round(tinyColor.getAlpha() * 100);

  return (
    <UiButton
      type="action"
      className={classNames(styles.uiColorPickerButton, className)}
      style={{ backgroundColor: value, ...style }}
      {...btnProps}
    >
      <UiSpace size="small">
        <UiTypography.Text type={type}>#{hex}</UiTypography.Text>
        <UiTypography.Text type={type}>{alpha}%</UiTypography.Text>
      </UiSpace>
    </UiButton>
  );
};
