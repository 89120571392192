// Dynamic palette
/** @deprecated: используется только для const [{ variables: themeVariables }] = useTheme(); */
const themeVariables = {
  // Brand
  colorBrand: '#0077FF',
  colorPortalHat: '#0077FF',
  colorBrand04: 'rgba(0, 119, 255, 0.04)',
  colorBrand08: 'rgba(0, 119, 255, 0.08)',
  colorBrand16: 'rgba(0, 119, 255, 0.16)',
  colorBrand32: 'rgba(0, 119, 255, 0.32)',
  colorBrandFaded: '#B6CFEB',
  colorHoverOnBrand: '#0069E0',
  colorInputOnBrand: '#2C8EFF',
  colorLinkBrand: '#1C67BE',
  colorTextOnBrand: '#FFFFFF',

  // Input, background, text // TODO: не изменяются в runtime, перенести в themePalette
  colorInput: '#F9FAFB',
  colorStroke: '#E9ECF2',
  colorBgPrimary: '#FFFFFF',
  colorBgSecondary: '#F2F4F7',
  colorTextPrimary: '#252629',
  colorTextSecondary: '#656970',
  colorTextTertiary: '#9CA3AD',
};

const themePalette = {
  colorBlack: '#252629',
  colorFade32: 'rgba(31, 32, 34, 0.32)',
  colorFade64: 'rgba(31, 32, 34, 0.64)',

  // Text
  colorTextInvert: '#fff',

  // Additional Shades/Tones
  colorIcon: '#A5ACB8',

  // Statuses
  colorInfo: '#8676ff',
  colorInfoBg: 'rgba(208, 237, 255, 0.8)',
  colorSuccess: '#17bc85',
  colorSuccessBg: 'rgba(213, 245, 225, 0.8)',
  colorNegative: '#f05a4f',
  colorNegativeBg: 'rgba(245, 220, 218, 0.8)',
  colorNegative08: 'rgba(240, 90, 79, 0.08)',
  colorNegative16: 'rgba(240, 90, 79, 0.16)',
  colorNegativeFaded: '#F9BAB5',
  colorWarning: '#ffc633',
  colorWarningBg: 'rgba(250, 238, 216, 0.8)',
  colorWarningFaded: '#ff9b62',

  // Accidents
  colorAccidentRed: '#FA7A7A',
  colorAccidentCoral: '#FF8C62',
  colorAccidentOrange: '#FFB35B',
  colorAccidentLightOrange: '#FAEED8CC',
  colorAccidentYellow: '#D8D35D',
  colorAccidentLime: '#77D986',
  colorAccidentSea: '#5AD1A6',
  colorAccidentTurquoise: '#5AD1CA',
  colorAccidentCyan: '#52CAE4',
  colorAccidentBlue: '#92B2EF',
  colorAccidentLavender: '#C380ED',
  colorAccidentPink: '#E290DA',
  colorAccidentMagenta: '#F095A7',
  colorAccidentTagSilver: '#B7BFC6',
  colorAccidentGold: '#CCBE9B',
  colorAccidentBlue2: '#45BCFF',
  colorAccidentBlueDark: '#669AFF',

  // Calendar
  colorTaskGreen: 'rgba(213, 245, 225, 0.8)',
  colorTaskBlue: 'rgba(208, 237, 255, 0.8)',
  colorTaskYellow: 'rgba(250, 238, 216, 0.8)',
  colorTaskRed: 'rgba(245, 220, 218, 0.8)',
  colorTaskUnconfirmed: 'rgba(233, 236, 242, 0.8)',
  colorTaskPast: 'rgba(233, 236, 242, 0.4)',
  colorTimeSelect: 'rgba(62, 172, 235, 1)',
  colorBrand08Untransperent: 'rgba(255, 239, 241, 1)',

  // Colors for static QR-code themePalette.black и themePalette.white
  white: '#fff',
  black: '#000',

  // Other
  colorGrayDark: '#333333',
  colorGrayIcon: '#beced6',
  colorStrokeDark: '#d5d8de',
  colorStrokeLight: '#e4e7ed',
  colorShadowAvatar: 'rgba(46, 48, 51, 0.08)',
  colorSkeleton: '#e4e7ed',
  colorSkeletonHighlight: '#f5f5f5',
  colorFormItemRequired: '#ff4d4f',
  colorTagSilver: 'rgba(183, 191, 198, 1)',
};

const themeWeights = {
  btnFontWeight: '600',
};

const themeSizes = {
  heightBase: '40px',
  heightLG: '48px',
  heightSM: '32px',

  lineHeight: '20px',
  lineHeightBase: '1.2',
  borderRadiusBase: '4px',
  borderRadiusLarge: '8px',
  borderRadiusSmall: '2px',

  heading2FontSize: '24px',
  heading3FontSize: '17px',
  heading4FontSize: '15px',
  heading5FontSize: '13px',

  btnHeightBase: '40px',
  btnHeightLg: '48px',
  btnHeightSm: '32px',
  btnBorderWidth: '2px',

  btnFontSize: '15px',
  btnPaddingHorizontalBase: '16px',

  selectSingleItemHeightLg: '48px',
  selectDropdownHeight: '40px',
  selectDropdownFontSize: '15px',
  selectDropdownLineHeight: '20px',

  baseIconSize: '20px',

  modalHeaderBorderWidth: '0',

  paginationItemSizeSm: '40px',

  tabsHorizontalPaddingSm: '16px 0',
  tabsHorizontalPadding: '22px 0',
  tabsHorizontalPaddingLg: '30px 0',
  tabsHorizontalMargin: 24,

  tableCellSelectionPadding: '18px',

  avatarSizeExtraSmall: '24px',
  avatarSizeSmall: '32px',
  avatarSizeMiddle: '40px',
  avatarSizeLarge: '48px',

  selectionColumnWidth: '68px',
};

const themeInput = {
  heightBase: '40px',
  heightLg: '48px',
  heightSm: '32px',
  paddingHorizontalBase: '15px',
  paddingHorizontalLg: '15px',
  paddingHorizontalSm: '11px',
  paddingVerticalBase: '9px',
  paddingVerticalLg: '13px',
  paddingVerticalSm: '5px',
};

const themeWidths = {
  layoutXSWidth: '768px', // extra small
  layoutSMWidth: '992px', // small
  layoutMDWidth: '1200px', // medium
  layoutLGWidth: '1440px', // large
  layoutXLWidth: '1800px', // extra large
};

const layoutSizes = {
  screenXS: '480px',
  screenSM: '576px',
  screenMD: '768px',
  screenLG: '1024px',
  screenXL: '1280px',
  screenXXL: '1920px',

  navbarWidth: '260px',
  breadcrumbHeight: '64px',
  navbarCollapsedWidth: '64px',
  headerHeight: '72px',

  outerGutterLG: '20px',
  outerGutterXS: '0px',

  innerGutterLG: '20px',
  innerGutterXS: '8px',

  contentMaxWidth: '792px',
  siderMaxWidth: '405px',
};

const baseSpace = 4;

const spaceSizes = {
  /** 4 */
  space2XS: baseSpace,
  /** 8 */
  spaceXS: baseSpace * 2,
  /** 12 */
  spaceS: baseSpace * 3,
  /** 16 */
  spaceM: baseSpace * 4,
  /** 20 */
  spaceL: baseSpace * 5,
  /** 24 */
  spaceXL: baseSpace * 6,
  /** 32 */
  space2XL: baseSpace * 8,
  /** 40 */
  space3XL: baseSpace * 10,
  /** 48 */
  space4XL: baseSpace * 12,
};

const themeOffsets = {
  modalOffset: 72,
  widgetMargin: '20px',
};

const themeLongRead = {
  /** DEFAULT PROPS */
  defaultFontSize: '16px',
  defaultLineHeight: '24px',

  /** P */
  paragraphFontSize: '16px',
  paragraphLineHeight: '24px',
  paragraphMargin: '16px 0',

  /** H1 */
  heading1FontSize: '40px',
  heading1LineHeight: '44px',
  heading1FontWeight: 'bold',
  heading1Margin: '24px 0',

  /** H2 */
  heading2FontSize: '32px',
  heading2LineHeight: '40px',
  heading2FontWeight: 'bold',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  heading2Margin: '24px 0 8px',

  /** H3 */
  heading3FontSize: '28px',
  heading3LineHeight: '36px',
  heading3FontWeight: 'bold',
  heading3Margin: '24px 0 8px',

  /** H4, H5, H6 */
  heading4FontSize: '24px',
  heading4LineHeight: '32px',
  heading4FontWeight: 'bold',
  heading4Margin: '24px 0 8px',

  /** OL, UL, LI */
  listMargin: '10px 0',
  listLeftPadding: '32px',
  listItemFontSize: '19px',
  listItemLineHeight: '28px',
  listItemMargin: '6px 0',
  listBulletWidth: '18px',
  listBulletGap: '-12px',

  /** UL LI */
  unorderedListBulletSize: '10px',
  unorderedListBulletOffsetX: '-14px',
  unorderedListBulletOffsetY: '10px',
  unorderedListBulletBorderRadius: '2px',

  /** OL LI */
  orderedListItemBulletTop: '4px',
  orderedListItemBulletHeight: '18px',
  orderedListItemBulletFontSize: '13px',
  orderedListItemBulletLineHeight: '14px',
  orderedListItemBulletFontWeight: '600',

  /** BLOCKQUOTE */
  blockquoteFontSize: '19px',
  blockquoteLineHeight: '28px',
  blockquoteMargin: '24px 0',
  blockquotePadding: '20px 24px',
  blockquoteFontWeight: '600',
  blockquoteBorderRadius: '4px',

  /** A */
  anchorTextDecoration: 'none',
  anchorHoverTextDecoration: 'underline',

  // TODO Image
  // TODO Image Gallery
};

const themeFonts = {
  fontFamily: "'Proxima Nova', arial, sans-serif",
};

module.exports = {
  themePalette,
  themeVariables,
  themeWeights,
  themeWidths,
  themeOffsets,
  themeSizes,
  themeLongRead,
  themeInput,
  themeFonts,
  layoutSizes,
  spaceSizes,
};
