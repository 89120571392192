import { Typography } from 'antd';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';
import React, { CSSProperties, forwardRef } from 'react';

export interface UiTypographyParagraphProps extends ParagraphProps, Pick<CSSProperties, 'whiteSpace'> {}

export const UiTypographyParagraph = forwardRef<HTMLElement, UiTypographyParagraphProps>((props, ref) => {
  const { style, whiteSpace } = props;

  return <Typography.Paragraph ref={ref} {...props} style={{ ...style, whiteSpace }} />;
});
