import { Checkbox as AntCheckbox } from 'antd';
import { CheckboxProps, CheckboxGroupProps } from 'antd/es/checkbox';
import { CheckboxOptionType } from 'antd/es/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxGroupContext } from 'antd/lib/checkbox/Group';
import classNames from 'classnames';
import React, { FC } from 'react';

import { useAbsolutePlacementShift, AbstractAbsoluteShiftPlacement } from '../../hooks';
import { UiCheckboxMenuProps as UiCheckboxMenuPropsType } from './menu';

export type UiCheckboxProps = CheckboxProps & AbstractAbsoluteShiftPlacement;
export type UiCheckboxGroupContext = CheckboxGroupContext;
export type UiCheckboxValueType = string | number | boolean;
export type UiCheckboxGroupProps = CheckboxGroupProps;
export type UiCheckboxMenuProps = UiCheckboxMenuPropsType;
export type UiCheckboxChangeEvent = CheckboxChangeEvent;
export type UiCheckboxOptionType = CheckboxOptionType;
export type UiCheckboxGroupValueProps = Array<UiCheckboxValueType>;
export type UiCheckboxChangeEventHandler = (e: UiCheckboxChangeEvent) => void;
export const getInvertUiCheckboxValue = (value?: boolean): boolean => !value;

export const UiCheckbox: FC<UiCheckboxProps> = (props) => {
  const { placement, shift, style, className, ...restProps } = props;
  const { placementStyles, placementCls } = useAbsolutePlacementShift({ placement, shift });

  return (
    <AntCheckbox
      {...restProps}
      className={classNames(placementCls, className)}
      style={{ ...style, ...placementStyles }}
    />
  );
};
