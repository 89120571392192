import classNames from 'classnames';
import React, { FC } from 'react';

import ArrowFrontSVG from '@vkph/ui/svg/arrow-front.svg';
import CalendarSvg from '@vkph/ui/svg/calendar.svg';

import { UiIcon } from '../../icon';
import { datePickerFormats, UiDatePickerFormatTypeProps } from '../UiDatePicker';
import { UiDatePickerClearIcon } from '../clear-icon/UiDatePickerClearIcon';
import { DateFnsPicker } from '../date-fns-picker/DateFnsPicker';
import styles from './UiDatePickerRange.scss';

type DateFnsPickerRangePickerProps = typeof DateFnsPicker.RangePicker.defaultProps;
export interface UiDatePickerRangeBaseProps extends UiDatePickerFormatTypeProps {
  hasFullWidth?: boolean;
}

export type UiDatePickerRangeProps = UiDatePickerRangeBaseProps & DateFnsPickerRangePickerProps;

export const UiDatePickerRange: FC<UiDatePickerRangeProps> = (props) => {
  const { formatType = 'date', className, hasFullWidth = false, ...rest } = props;

  const pickerClassName = classNames(
    styles.uiDatePickerRange,
    {
      [styles.uiDatePickerRange__fullWidth]: hasFullWidth,
    },
    className,
  );

  return (
    <DateFnsPicker.RangePicker
      format={datePickerFormats[formatType]}
      separator={<UiIcon component={ArrowFrontSVG} width={20} height={20} />}
      suffixIcon={<CalendarSvg />}
      allowClear={{ clearIcon: UiDatePickerClearIcon }}
      className={pickerClassName}
      {...rest}
    />
  );
};
