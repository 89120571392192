import { FC } from 'react';

import { UiAvatarProps, UiAvatar as OriginUiAvatar } from './UiAvatar';
import { UiAvatarGroup } from './group/UiAvatarGroup';

export type * from './group/UiAvatarGroup';
export type * from './UiAvatar';
export { AVATAR_SIZE_LIMIT, getNumberSizeAvatar } from './UiAvatar';

export type UiAvatarComposition = FC<UiAvatarProps> & {
  Group: typeof UiAvatarGroup;
};

export const UiAvatar = OriginUiAvatar as UiAvatarComposition;

UiAvatar.Group = UiAvatarGroup;
