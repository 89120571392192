import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import MobileArrowCollapseSvg from '@vkph/ui/svg/arrow-collapse-2.svg';
import ArrowCollapseSvg from '@vkph/ui/svg/arrow-collapse.svg';
import MobileArrowExpandSvg from '@vkph/ui/svg/arrow-expand-2.svg';
import ArrowExpandSvg from '@vkph/ui/svg/arrow-expand.svg';

import { useElementSize, useSpace, useToggle } from '../../../hooks';
import { UiButton } from '../../button';
import { UiIcon } from '../../icon';
import styles from './UiCollapseContent.scss';

export type UiCollapseContentProps = {
  height?: number;
  compact?: boolean;
};

const icons: Record<'small' | 'large', SvgrComponent[]> = {
  small: [MobileArrowCollapseSvg, MobileArrowExpandSvg],
  large: [ArrowCollapseSvg, ArrowExpandSvg],
};

export const UiCollapseContent: FC<PropsWithChildren<UiCollapseContentProps>> = (props) => {
  const { children, compact, height = 200 } = props;

  const { ref, height: childrenHeight } = useElementSize();
  const [isExpanded, toggleIsExpanded] = useToggle([false, true]);
  const { spaceL, spaceXS } = useSpace();
  const isButtonVisible = childrenHeight > height;

  const [CollapseSvg, ExpandSvg] = icons[compact ? 'small' : 'large'];
  const label = isExpanded ? 'Свернуть' : 'Показать полностью';
  const icon = isExpanded ? CollapseSvg : ExpandSvg;

  const content = <div ref={ref}>{children}</div>;

  if (!isButtonVisible) return content;

  return (
    <>
      <div
        style={{ height: isExpanded ? childrenHeight : height }}
        className={classNames(styles.uiCollapseContent, {
          [styles.uiCollapseContent__overlay_hidden]: isExpanded,
        })}
      >
        {content}
      </div>
      <UiButton
        block
        size="large"
        label={compact ? undefined : label}
        style={{ marginTop: compact ? spaceXS : spaceL, width: '100%' }}
        type={compact ? 'link-secondary' : 'secondary'}
        icon={<UiIcon component={icon} width={20} height={20} />}
        onClick={() => toggleIsExpanded()}
      />
    </>
  );
};
