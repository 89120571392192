import { Modifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { getEventCoordinates } from '@dnd-kit/utilities';

import { UiDnDDragEndEvent } from './context/UiDnDContext';

export type { SyntheticListenerMap as UiDnDSyntheticListenerMapProps } from '@dnd-kit/core/dist/hooks/utilities';
export { CSS as DnDCSS } from '@dnd-kit/utilities';
export type DnDModifier = Modifier;

export {
  arrayMove as getDnDArrayMove,
  horizontalListSortingStrategy as dndHorizontalListSortingStrategy,
  verticalListSortingStrategy as dndVerticalListSortingStrategy,
} from '@dnd-kit/sortable';

export {
  restrictToVerticalAxis as dndRestrictToVerticalAxis,
  restrictToParentElement as dndRestrictToParentElement,
} from '@dnd-kit/modifiers';

export const getDnDEventCoordinates = getEventCoordinates;

export const snapDnDItemLeftToCursor =
  (options?: { horizontalShift?: number }): Modifier =>
  ({ activatorEvent, draggingNodeRect, transform }) => {
    if (draggingNodeRect && activatorEvent) {
      const activatorCoordinates = getDnDEventCoordinates(activatorEvent);
      const defaultShift = 24;

      if (!activatorCoordinates) {
        return transform;
      }

      const offsetX = activatorCoordinates.x - draggingNodeRect.left;
      const offsetY = activatorCoordinates.y - draggingNodeRect.top;

      return {
        ...transform,
        x: transform.x + offsetX - draggingNodeRect.width + (options?.horizontalShift || defaultShift),
        y: transform.y + offsetY - draggingNodeRect.height / 2,
      };
    }

    return transform;
  };

export const getDnDDragEndArray = <T>(event: UiDnDDragEndEvent<T>, items: T[]) => {
  const { active, over } = event;
  const activeIndex = active.data?.current?.sortable?.index;
  const overIndex = over?.data?.current?.sortable?.index;

  if (over && activeIndex >= 0 && overIndex >= 0 && activeIndex !== overIndex) {
    return arrayMove(items, activeIndex, overIndex);
  }

  return items;
};
