import { Typography } from 'antd';
import { BaseType } from 'antd/lib/typography/Base';
import { LinkProps } from 'antd/lib/typography/Link';
import { TitleProps } from 'antd/lib/typography/Title';

import { UiTypographyFootnote } from './footnote/UiTypographyFootnote';
import { UiTypographyParagraph } from './paragraph/UiTypographyParagraph';
import { UiTypographySymbol } from './symbol/UiTypographySymbol';
import {
  UiTypographyText,
  UiTypographyTextProps as TypographyTextProps,
  UiTypographyTextType as TypographyTextType,
} from './text/UiTypographyText';

export type { UiTypographyParagraphProps } from './paragraph/UiTypographyParagraph';

export type UiTypographyBaseType = BaseType;
export type UiTypographyTitleProps = TitleProps;
export type UiTypographyTextProps = TypographyTextProps;
export type UiTypographyTextType = TypographyTextType;
export type UiTypographyLinkProps = LinkProps;

export interface UiTypographyComposition {
  Text: typeof UiTypographyText;
  Link: typeof Typography.Link;
  Title: typeof Typography.Title;
  Paragraph: typeof UiTypographyParagraph;
  Symbol: typeof UiTypographySymbol;
  Footnote: typeof UiTypographyFootnote;
}

export const UiTypography: UiTypographyComposition = {
  Text: UiTypographyText,
  Link: Typography.Link,
  Title: Typography.Title,
  Paragraph: UiTypographyParagraph,
  Footnote: UiTypographyFootnote,
  Symbol: UiTypographySymbol,
};

export { UiTypographySymbol, UiTypographySymbolName } from './symbol/UiTypographySymbol';
export type { UiTypographySymbolProps } from './symbol/UiTypographySymbol';
