import {
  Active,
  Collision,
  DndContextProps,
  DndContext,
  Over,
  Translate,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import React, { MutableRefObject } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UiDnDAnyData = Record<string, any>;
export type UiDnDData<T = UiDnDAnyData> = T & UiDnDAnyData;
type UiDnDDataRef<T = UiDnDAnyData> = MutableRefObject<UiDnDData<T> | undefined>;
export interface UiDnDActiveType<T = UiDnDData> extends Omit<Active, 'data'> {
  data: UiDnDDataRef<T>;
}

export interface UiDnDOverType<T = UiDnDData> extends Omit<Over, 'data'> {
  data: UiDnDDataRef<T>;
}

interface UiDnDDragEvent<T> {
  activatorEvent: Event;
  active: UiDnDActiveType<T>;
  collisions: Collision[] | null;
  delta: Translate;
  over: UiDnDOverType<T> | null;
}
export interface UiDnDDragStartEvent<T = UiDnDData> extends Pick<UiDnDDragEvent<T>, 'active'> {}
export interface UiDnDDragMoveEvent<T = UiDnDData> extends UiDnDDragEvent<T> {}
export interface UiDnDDragOverEvent<T = UiDnDData> extends UiDnDDragMoveEvent<T> {}
export interface UiDnDDragEndEvent<T = UiDnDData> extends UiDnDDragEvent<T> {}
export interface UiDnDDragCancelEvent<T = UiDnDData> extends UiDnDDragEndEvent<T> {}

export interface UiDnDContextProps<T = UiDnDData>
  extends Omit<DndContextProps, 'onDragStart' | 'onDragMove' | 'onDragOver' | 'onDragEnd' | 'onDragCancel'> {
  onDragStart?(event: UiDnDDragStartEvent<T>): void;
  onDragMove?(event: UiDnDDragMoveEvent<T>): void;
  onDragOver?(event: UiDnDDragOverEvent<T>): void;
  onDragEnd?(event: UiDnDDragEndEvent<T>): void;
  onDragCancel?(event: UiDnDDragCancelEvent<T>): void;
}

export const UiDnDContext = <T,>(props: UiDnDContextProps<T>) => {
  const { sensors, ...restProps } = props;

  const defaultSensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  );

  return <DndContext sensors={sensors || defaultSensors} {...restProps} />;
};
