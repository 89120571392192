import { Input } from 'antd';
import { InputProps, InputRef, SearchProps } from 'antd/es/input';
import { PasswordProps } from 'antd/lib/input';
import classNames from 'classnames';
import React, {
  forwardRef,
  RefAttributes,
  FC,
  ForwardRefExoticComponent,
  useRef,
  RefObject,
  ReactElement,
  isValidElement,
  cloneElement,
} from 'react';

import ClearSvg from '@vkph/ui/svg/clear.svg';
import HideSvg from '@vkph/ui/svg/hide.svg';
import SearchIcon from '@vkph/ui/svg/search.svg';
import ViewSvg from '@vkph/ui/svg/view.svg';

import { UiIcon } from '../icon';
import styles from './UiInput.scss';

const ccn = classNames.bind(styles);

export type UiInputRef = InputRef;

export interface UiInputProps extends Omit<InputProps, 'bordered'> {
  onClear?: () => void;
  clearIcon?: SvgrComponent;
  noStyle?: boolean;
}

const { Search } = Input;

type UiInputComponent = ForwardRefExoticComponent<RefAttributes<UiInputRef> & UiInputProps>;

const UiInputBase: UiInputComponent = forwardRef((props, ref) => {
  const { allowClear, value, className, clearIcon, onClear, suffix, noStyle, prefix, ...restProps } = props;
  const ClearIcon = clearIcon || ClearSvg;
  const showClear = value && allowClear && onClear;
  const internalRef = useRef<UiInputRef>(null);
  const inputRef = ref || internalRef;

  const handleSuffixClick = () => {
    requestAnimationFrame(() => {
      const inputElement = (inputRef as RefObject<UiInputRef>)?.current;

      inputElement?.blur();
    });
  };

  const suffixElement = showClear ? (
    <ClearIcon className={styles.uiInput__searchClear} onClick={onClear} />
  ) : (
    isValidElement(suffix) &&
    cloneElement(suffix as ReactElement, {
      onClick: (...args: unknown[]) => {
        handleSuffixClick();

        return suffix.props.onClick?.(...args);
      },
    })
  );

  return (
    <Input
      {...restProps}
      prefix={prefix}
      value={value}
      allowClear={false} // Отключил стандартный, нельзя переопределить
      className={ccn(styles.uiInput, className, {
        [styles.uiInput_noStyle]: noStyle,
        [styles.uiInput__prefix_text]: typeof prefix === 'string',
      })}
      suffix={suffixElement}
      ref={inputRef}
    />
  );
});

export interface UiInputComposition {
  Group: typeof Input.Group;
  Search: FC<UiInputProps>;
  SearchBox: FC<SearchProps>;
  Password: FC<PasswordProps>;
  TextArea: typeof Input.TextArea;
}

export const UiInput = UiInputBase as UiInputComponent & UiInputComposition;

export const UiInputSearch: FC<UiInputProps> = (props) => {
  return <UiInput {...props} prefix={<SearchIcon className={styles.uiInput__searchPrefix} />} />;
};

export const SearchBox: FC<SearchProps> = (props) => {
  return <Search {...props} prefix={<SearchIcon className={styles.uiInput__searchPrefix} />} />;
};

export const UiInputPassword: FC<PasswordProps> = (props) => {
  return (
    <Input.Password
      iconRender={(visible) => <UiIcon component={visible ? ViewSvg : HideSvg} width={20} height={20} />}
      {...props}
    />
  );
};

UiInput.Group = Input.Group;
UiInput.Search = UiInputSearch;
UiInput.SearchBox = SearchBox;
UiInput.Password = UiInputPassword;
UiInput.TextArea = Input.TextArea;
