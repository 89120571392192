// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ec05c1cc_filter-dropdown .ant-list-item{padding:8px 16px}.ec05c1cc_filter-dropdown .ant-list,.ec05c1cc_filter-dropdown .ant-checkbox-group,.ec05c1cc_filter-dropdown .ant-radio-group,.ec05c1cc_filter-dropdown .ant-checkbox-wrapper,.ec05c1cc_filter-dropdown .ant-radio-wrapper{flex:1}.ec05c1cc_filter-dropdown__list-wrapper{max-height:204px;padding:12px 0;overflow-x:hidden;overflow-y:auto}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/table/column/filter-dropdown/UiTableFilterDropdown.scss"],"names":[],"mappings":"AAGI,yCACE,gBAAA,CAGF,0NAKE,MAAA,CAIJ,wCACE,gBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.filter-dropdown {\n  :global {\n    .ant-list-item {\n      padding: $spaceXS $spaceM;\n    }\n\n    .ant-list,\n    .ant-checkbox-group,\n    .ant-radio-group,\n    .ant-checkbox-wrapper,\n    .ant-radio-wrapper {\n      flex: 1;\n    }\n  }\n\n  &__list-wrapper {\n    max-height: 204px;\n    padding: $spaceS 0;\n    overflow-x: hidden;\n    overflow-y: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter-dropdown": "ec05c1cc_filter-dropdown",
	"filterDropdown": "ec05c1cc_filter-dropdown",
	"filter-dropdown__list-wrapper": "ec05c1cc_filter-dropdown__list-wrapper",
	"filterDropdown__listWrapper": "ec05c1cc_filter-dropdown__list-wrapper"
};
export default ___CSS_LOADER_EXPORT___;
