import { FC } from 'react';

import { UiIcon as Icon, UiIconProps } from './UiIcon';
import { UiIconCircle } from './circle/UiIconCircle';
import { UiIconHint } from './hint/UiIconHint';
import { UiIconLabel, UiIconLabelProps as UiIconLabelPropsImport } from './label/UiIconLabel';

export type UiIconLabelProps = UiIconLabelPropsImport;
export * from './UiIcon';

type UiIconComposition = {
  Circle: typeof UiIconCircle;
  Label: typeof UiIconLabel;
  Hint: typeof UiIconHint;
};

export const UiIcon = Icon as FC<UiIconProps> & UiIconComposition;

UiIcon.Circle = UiIconCircle;
UiIcon.Label = UiIconLabel;
UiIcon.Hint = UiIconHint;
