import { Switch } from 'antd';
import { SwitchProps } from 'antd/es/switch';
import React, { FC, isValidElement, ReactNode } from 'react';

import { useSpace } from '../../providers/space';
import { UiSpace, UiSpaceProps } from '../space';
import { UiTypography } from '../typography';

export interface UiSwitchProps extends SwitchProps {
  label?: ReactNode;
  containerProps?: UiSpaceProps;
}

export const UiSwitch: FC<UiSwitchProps> = (props) => {
  const { label, disabled, containerProps, ...restProps } = props;
  const { spaceXS } = useSpace();
  const isLabelNode = isValidElement(label);

  return (
    <UiSpace size={spaceXS} {...containerProps}>
      <Switch disabled={disabled} {...restProps} />
      {isLabelNode && label}
      {!isLabelNode && <UiTypography.Text disabled={disabled}>{label}</UiTypography.Text>}
    </UiSpace>
  );
};
