// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._77c9f24e_ui-form-item_label-top>.ant-row{align-items:start}._77c9f24e_ui-form-item_label-top>.ant-row > .ant-form-item-label > label{height:auto}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/form/item/UiFormItem.scss"],"names":[],"mappings":"AAGI,2CACE,iBAAA,CAGF,0EACE,WAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ui-form-item {\n  &_label-top {\n    & > :global(.ant-row) {\n      align-items: start;\n    }\n\n    & > :global(.ant-row > .ant-form-item-label > label) {\n      height: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-form-item_label-top": "_77c9f24e_ui-form-item_label-top",
	"uiFormItem_labelTop": "_77c9f24e_ui-form-item_label-top"
};
export default ___CSS_LOADER_EXPORT___;
