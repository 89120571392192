import { Upload } from 'antd';
import { FC } from 'react';

import { UiUpload as UploadInner, UiUploadProps } from './UiUpload';
import { UiUploadDragger, UiUploadDraggerProps as UploadDraggerProps } from './dragger/UiUploadDragger';

export type UiUploadDraggerProps = UploadDraggerProps;

export * from './guards';
export type * from './UiUpload';

export enum UiUploadFileStatusType {
  Error = 'error',
  Success = 'success',
  Done = 'done',
  Uploading = 'uploading',
  Removed = 'removed',
}

export interface UiUploadComposition extends FC<UiUploadProps> {
  Dragger: typeof UiUploadDragger;
  LIST_IGNORE: typeof Upload.LIST_IGNORE;
}

export const UiUpload = UploadInner as UiUploadComposition;

UiUpload.Dragger = UiUploadDragger;
UiUpload.LIST_IGNORE = Upload.LIST_IGNORE;
