import { Form as AntForm, FormItemProps as AntFormItemProps, TooltipProps as AntTooltipProps } from 'antd';
import classNames from 'classnames';
import React, { FC, isValidElement, useMemo } from 'react';

import FaqSvg from '@vkph/ui/svg/faq.svg';

import { UiIcon } from '../../icon';
import styles from './UiFormItem.scss';

const isTooltip = (tooltip: AntFormItemProps['tooltip']): tooltip is AntTooltipProps => {
  if (!tooltip) {
    return false;
  }

  return typeof tooltip === 'object' && !isValidElement(tooltip);
};

interface Props extends Omit<AntFormItemProps, 'labelAlign'> {
  labelAlign?: AntFormItemProps['labelAlign'] | 'top';
}

export const UiFormItem: FC<Props> = (props) => {
  const { tooltip, className, labelAlign = 'left', ...rest } = props;

  const itemTooltip = useMemo(() => {
    if (!tooltip) {
      return tooltip;
    }

    if (isTooltip(tooltip)) {
      return {
        ...tooltip,
        icon: tooltip?.icon || <UiIcon component={FaqSvg} width={20} height={20} />,
      };
    }

    return {
      title: tooltip,
      icon: <UiIcon component={FaqSvg} width={20} height={20} />,
    };
  }, [tooltip]);

  return (
    <AntForm.Item
      className={classNames(className, styles.uiFormItem, {
        [styles.uiFormItem_labelTop]: labelAlign === 'top',
      })}
      labelAlign={labelAlign === 'top' ? 'left' : labelAlign}
      tooltip={itemTooltip}
      {...rest}
    />
  );
};
