// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aa1042ce_ui-collapse-content{position:relative;overflow:hidden;transition:all .4s ease-out}.aa1042ce_ui-collapse-content__overlay_hidden[class]:after{content:unset}.aa1042ce_ui-collapse-content:after{bottom:0;content:\" \";width:100%;height:72px;position:absolute;background:linear-gradient(transparent, var(--default-themeVariables-colorBgPrimary))}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/collapse/content/UiCollapseContent.scss"],"names":[],"mappings":"AACA,8BACE,iBAAA,CACA,eAAA,CACA,2BAAA,CAEA,2DACE,aAAA,CAGF,oCACE,QAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,qFAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ui-collapse-content {\n  position: relative;\n  overflow: hidden;\n  transition: all 0.4s ease-out;\n\n  &__overlay_hidden[class]:after {\n    content: unset;\n  }\n\n  &:after {\n    bottom: 0;\n    content: ' ';\n    width: 100%;\n    height: 72px;\n    position: absolute;\n    background: linear-gradient(transparent, $colorBgPrimary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-collapse-content": "aa1042ce_ui-collapse-content",
	"uiCollapseContent": "aa1042ce_ui-collapse-content",
	"ui-collapse-content__overlay_hidden": "aa1042ce_ui-collapse-content__overlay_hidden",
	"uiCollapseContent__overlay_hidden": "aa1042ce_ui-collapse-content__overlay_hidden"
};
export default ___CSS_LOADER_EXPORT___;
