import { Radio as AntRadio } from 'antd';
import { RadioProps, RadioGroupProps, RadioChangeEvent as AntRadioChangeEvent } from 'antd/es/radio';
import classNames from 'classnames';
import React, { FC } from 'react';

import { useAbsolutePlacementShift, AbstractAbsoluteShiftPlacement } from '../../hooks';
import { UiRadioMenu } from './menu/UiRadioMenu';

export type { UiRadioMenuProps } from './menu/UiRadioMenu';
export type UiRadioProps = RadioProps & AbstractAbsoluteShiftPlacement;
export type UiRadioChangeEvent = AntRadioChangeEvent;
export type UiRadioChangeEventHandler = (e: AntRadioChangeEvent) => void;
export type UiRadioGroupProps = RadioGroupProps;
export type UiRadioGroupOptions = UiRadioGroupProps['options'];
export type UiRadioGroupOnChange = NonNullable<UiRadioGroupProps['onChange']>;
export interface UiRadioComposition {
  Group: typeof AntRadio.Group;
  Button: typeof AntRadio.Button;
  Menu: typeof UiRadioMenu;
}

export const UiRadio: FC<UiRadioProps> & UiRadioComposition = (props) => {
  const { placement, shift, style, className, ...restProps } = props;
  const { placementStyles, placementCls } = useAbsolutePlacementShift({ placement, shift });

  return (
    <AntRadio
      {...restProps}
      className={classNames(placementCls, className)}
      style={{ ...style, ...placementStyles }}
    />
  );
};

UiRadio.Group = AntRadio.Group;
UiRadio.Button = AntRadio.Button;
UiRadio.Menu = UiRadioMenu;
