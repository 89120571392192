import React, { createContext, FC, PropsWithChildren, useLayoutEffect, useMemo } from 'react';

import { useToggle } from '../../../hooks';
import { variables } from '../../../providers/theme';
import { LAYOUT_NAVBAR_COLLAPSED_BREAKPOINT } from '../constants';
import { useBreakpoint } from '../hooks/useBreakpoint';

type Props = {
  hasHeader: boolean;
  hasNavbar: boolean;
};

export type LayoutContextValue = {
  outerGutter: string;
  innerGutter: string;
  hasHeader: boolean;
  hasNavbar: boolean;
  isNavbarCollapsed: boolean;
  toggleNavbar?: (value?: boolean) => void;
};

export const CONTEXT_DEFAULT_VALUE: LayoutContextValue = {
  outerGutter: variables.layoutSizes.outerGutterLG,
  innerGutter: variables.layoutSizes.innerGutterLG,
  hasHeader: true,
  hasNavbar: true,
  isNavbarCollapsed: true,
};

export const LayoutContext = createContext<LayoutContextValue>(CONTEXT_DEFAULT_VALUE);

export const LayoutProvider: FC<PropsWithChildren<Props>> = (props) => {
  const { hasHeader, hasNavbar, children } = props;
  const [isNavbarCollapsed, toggleIsNavbarCollapsed] = useToggle([false, true]);
  const breakpoints = useBreakpoint();

  useLayoutEffect(() => {
    if (!breakpoints[LAYOUT_NAVBAR_COLLAPSED_BREAKPOINT]) {
      toggleIsNavbarCollapsed?.(!breakpoints[LAYOUT_NAVBAR_COLLAPSED_BREAKPOINT]);
    }
  }, [breakpoints[LAYOUT_NAVBAR_COLLAPSED_BREAKPOINT]]);

  const contextValue = useMemo<LayoutContextValue>(() => {
    const { layoutSizes } = variables;
    const outerGutter = breakpoints.lg ? layoutSizes.outerGutterLG : layoutSizes.outerGutterXS;
    const innerGutter = breakpoints.lg ? layoutSizes.innerGutterLG : layoutSizes.innerGutterXS;

    return {
      outerGutter,
      innerGutter,
      hasHeader,
      hasNavbar,
      isNavbarCollapsed,
      toggleNavbar: toggleIsNavbarCollapsed,
    };
  }, [breakpoints.lg, hasHeader, hasNavbar, isNavbarCollapsed]);

  return <LayoutContext.Provider value={contextValue}>{children}</LayoutContext.Provider>;
};
