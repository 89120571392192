import classNames from 'classnames';
import React, { FC } from 'react';

import GrabberSvg from '@vkph/ui/svg/grabber.svg';

import { UiIcon, UiIconProps } from '../../../icon';
import type { UiIconLabelProps } from '../../../icon';
import styles from '../item/UiDnDSortableItem.scss';

export interface UiDnDSortableDragIconProps extends Pick<UiIconLabelProps, 'className'> {
  visible?: boolean;
  style?: UiIconProps['style'];
  component?: UiIconLabelProps['component'];
}

export const UiDnDSortableDragIcon: FC<UiDnDSortableDragIconProps> = (props) => {
  const { visible, style, className, component = GrabberSvg } = props;

  return (
    <UiIcon.Label
      style={style}
      component={component}
      className={classNames(
        className,
        styles.uiDndSortableDragIcon,
        visible && styles.uiDndSortableDragIcon_visible,
      )}
    />
  );
};
