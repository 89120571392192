import { Layout as AntLayout, LayoutProps as AntLayoutProps } from 'antd';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

import { variables } from '../../providers/theme';
import { LayoutBreadcrumb } from './breadcrumb';
import { LayoutContent } from './content';
import { LayoutHeader } from './header';
import { LayoutLoading } from './loading';
import { LayoutMain } from './main';
import { LayoutNavbar } from './navbar';
import { LayoutNoAccess } from './no-access';
import { LayoutProvider } from './provider';
import { LayoutSider } from './sider';

type LayoutComposition = {
  Header: typeof LayoutHeader;
  Navbar: typeof LayoutNavbar;
  Sider: typeof LayoutSider;
  Content: typeof LayoutContent;
  Main: typeof LayoutMain;
  Breadcrumb: typeof LayoutBreadcrumb;
  Loading: typeof LayoutLoading;
  NoAccess: typeof LayoutNoAccess;
};

export interface LayoutProps extends AntLayoutProps {
  header?: ReactNode;
  navbar?: ReactNode;
}

export const Layout: FC<PropsWithChildren<LayoutProps>> & LayoutComposition = (props) => {
  const { children, header, navbar, ...rest } = props;

  const hasHeader = Boolean(header);
  const hasNavbar = Boolean(navbar);

  return (
    <LayoutProvider hasHeader={hasHeader} hasNavbar={hasNavbar}>
      <AntLayout {...rest}>
        {hasHeader && <LayoutHeader>{header}</LayoutHeader>}
        <AntLayout
          style={{
            marginTop: hasHeader ? variables.layoutSizes.headerHeight : undefined,
            position: 'relative',
          }}
        >
          {hasNavbar && <LayoutNavbar>{navbar}</LayoutNavbar>}
          <AntLayout>{children}</AntLayout>
        </AntLayout>
      </AntLayout>
    </LayoutProvider>
  );
};

Layout.Header = LayoutHeader;
Layout.Navbar = LayoutNavbar;
Layout.Sider = LayoutSider;
Layout.Content = LayoutContent;
Layout.Main = LayoutMain;
Layout.Breadcrumb = LayoutBreadcrumb;
Layout.Loading = LayoutLoading;
Layout.NoAccess = LayoutNoAccess;
