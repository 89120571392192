import { Card, CardProps } from 'antd';
import classNames from 'classnames';
import React, { CSSProperties, forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';

import { UiConfigProviderSizeType } from '../config-provider';
import styles from './UiCard.scss';

export interface UiCardProps extends CardProps {
  shadow?: UiConfigProviderSizeType;
  emptyPadding?: boolean;
  emptyRadius?: boolean;
}

export type UiCardComponent = ForwardRefExoticComponent<UiCardProps & RefAttributes<HTMLDivElement>>;

export const UiCard: UiCardComponent = forwardRef((props, ref) => {
  const {
    bordered = false,
    emptyRadius = false,
    emptyPadding = false,
    shadow,
    size = 'small',
    className,
    styles: outerStyles,
    style,
    ...cardProps
  } = props;

  const cardStyles: CSSProperties = {
    ...(emptyRadius && { borderRadius: 0 }),
    ...style,
  };

  return (
    <Card
      ref={ref}
      size={size}
      bordered={bordered}
      style={cardStyles}
      styles={{ body: emptyPadding ? { padding: 0 } : undefined, ...outerStyles }}
      className={classNames(className, {
        [styles.uiCard_emptyPadding]: emptyPadding,
        [styles[`uiCard_shadow_${shadow}`]]: Boolean(shadow),
      })}
      {...cardProps}
    />
  );
});
