import { Layout } from 'antd';
import classNames from 'classnames';
import React, { FC, PropsWithChildren, HTMLAttributes } from 'react';

import styles from './LayoutHeader.scss';

type Props = HTMLAttributes<HTMLElement>;

export const LayoutHeader: FC<PropsWithChildren<Props>> = (props) => {
  const { children, className, ...rest } = props;

  return (
    <Layout.Header
      {...rest}
      className={classNames(styles.layoutHeader, styles.layoutHeader_fixed, className)}
    >
      {children}
    </Layout.Header>
  );
};
