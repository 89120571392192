import React, { FC } from 'react';

import { useSpace } from '../../../../providers/space';
import { UiFlex, UiFlexProps } from '../../../flex';

type Props = UiFlexProps;

export const LayoutSiderColumn: FC<Props> = (props) => {
  const { gap, vertical, ...restProps } = props;
  const { spaceL } = useSpace();

  return <UiFlex {...restProps} vertical gap={spaceL} />;
};
