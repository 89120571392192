import { FC } from 'react';

import { UiDatePicker as DatePicker, UiDatePickerProps } from './UiDatePicker';
import { UiDatePickerRange } from './range/UiDatePickerRange';

export * from './UiDatePicker';
export type { UiDatePickerRangeProps } from './range/UiDatePickerRange';

type UiDatePickerComposition = {
  RangePicker: typeof UiDatePickerRange;
};

export const UiDatePicker = DatePicker as FC<UiDatePickerProps> & UiDatePickerComposition;

UiDatePicker.RangePicker = UiDatePickerRange;
