import React, { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react';

import { usePaddingStyle, useSpace, useAbsolutePlacementShift, AbsolutePlacement } from '../../../hooks';
import { useTheme } from '../../../providers/theme';
import { useToken } from '../../config-provider';
import { UiFlex, UiFlexProps } from '../../flex';
import { UiTypography } from '../../typography';

export interface UiTableSelectionHeaderProps extends Pick<UiFlexProps, 'style'> {
  totalCount?: number;
  selectedCount?: number;
  extra?: ReactNode;
}

export const UiTableSelectionHeader: FC<PropsWithChildren<UiTableSelectionHeaderProps>> = (props) => {
  const { totalCount = 0, selectedCount = 0, extra, children, style } = props;
  const { spaceM, spaceXL } = useSpace();
  const [{ variables: themeVariables }] = useTheme();
  const wrapperPadding = usePaddingStyle([spaceXL]);
  const { token } = useToken();
  const selectionColumnWidth = token.Table?.selectionColumnWidth || 0;
  const { placementCls, placementStyles } = useAbsolutePlacementShift({
    placement: AbsolutePlacement.TopLeft,
    shift: {
      left: selectionColumnWidth,
    },
  });

  const wrapperStyle: CSSProperties = {
    ...wrapperPadding,
    ...style,
    ...placementStyles,
    width: `calc(100% - ${selectionColumnWidth}px)`,
    backgroundColor: themeVariables.colorTextOnBrand,
    borderBottom: `1px solid ${themeVariables.colorStroke}`,
  };

  const selectedInfoString = `${selectedCount} из ${totalCount}`;

  return (
    <UiFlex style={wrapperStyle} className={placementCls} align="center" justify="space-between">
      <UiFlex gap={spaceM} align="baseline">
        <UiTypography.Text>{selectedInfoString}</UiTypography.Text>
        {children}
      </UiFlex>

      {extra}
    </UiFlex>
  );
};
