import { Image as AntImage } from 'antd';
import classNames from 'classnames';
import isObject from 'lodash/isObject';
import { ImagePreviewType as AntImagePreviewType, ImageProps as AntImageProps } from 'rc-image/lib/Image';
import { ToolbarRenderInfoType } from 'rc-image/lib/Preview';
import { PreviewGroupPreview as AntPreviewGroupPreview } from 'rc-image/lib/PreviewGroup';
import React, { FC, PropsWithChildren, ReactNode, useMemo } from 'react';

import CloseSvg from '@vkph/ui/svg/close.svg';
import CommentAltSvg from '@vkph/ui/svg/comment-alt.svg';
import DeleteSvg from '@vkph/ui/svg/delete.svg';
import DownloadSvg from '@vkph/ui/svg/download.svg';
import RotateLeftSvg from '@vkph/ui/svg/rotate-left.svg';
import RotateSvg from '@vkph/ui/svg/rotate.svg';
import ShareSvg from '@vkph/ui/svg/share.svg';
import ZoomInSvg from '@vkph/ui/svg/zoom-in.svg';
import ZoomOutSvg from '@vkph/ui/svg/zoom-out.svg';

import { useSpace, useToggle } from '../../../hooks';
import { UiRow } from '../../grid';
import { UiIcon } from '../../icon';
import { UiSpace } from '../../space';
import { UiImageProps } from '../UiImage';
import styles from '../UiImage.scss';
import { UiImagePreviewButton } from './button/UiImagePreviewButton';
import {
  UiImageCustomContentPreview,
  UiImageCustomContentPreviewProps,
} from './image-custom-content-preview/UiImageCustomContentPreview';
import { useImageCustomContentPreview } from './image-custom-content-preview/hooks';

type UiImagePreviewComposition = {
  Button: typeof UiImagePreviewButton;
};

const SCALE_MIN = 0.2;
const SCALE_MAX = 10;
const SCALE_STEP = 0.2;

type BaseImagePreviewType = AntImagePreviewType & AntPreviewGroupPreview;

export interface UiImagePreviewPropExtended extends BaseImagePreviewType {
  toolbarExtra?: ReactNode;
  customize?: {
    onShare?: () => void;
    onDownload?: () => void;
    onDelete?: () => void;
    title?: ReactNode;
    subtitle?: ReactNode;
    content?: UiImageCustomContentPreviewProps['content'];
    sideBarPlacement?: UiImageCustomContentPreviewProps['placement'];
  };
  visible?: boolean;
}

interface UiImagePreviewProps extends Pick<UiImageProps, 'wrapperStyle'> {
  preview: UiImagePreviewPropExtended | true;
  items?: string[];
}
type UiImagePreviewComponent = FC<PropsWithChildren<UiImagePreviewProps>> & UiImagePreviewComposition;

type ToolbarRenderType = (
  originalNode: React.ReactElement,
  info: Omit<ToolbarRenderInfoType, 'current' | 'total'>,
) => React.ReactNode;

export const UiImagePreview: UiImagePreviewComponent = (props) => {
  const { preview, children, items } = props;
  const {
    customize,
    visible,
    onChange,
    current,
    onVisibleChange,
    ...restPreviewConfig
  }: UiImagePreviewPropExtended = isObject(preview) ? preview : {};
  const [isSidebarOpen, toggleSidebarOpen] = useToggle([false, true]);
  const { ref, getContainer, containerElement } = useImageCustomContentPreview();
  const { spaceXL } = useSpace();

  const getDefaultToolbar =
    (isSidebarOpened: boolean): ToolbarRenderType =>
    (_, { transform: { scale }, actions: { onRotateLeft, onRotateRight, onZoomOut, onZoomIn } }) =>
      (
        <UiRow justify="space-between" style={{ width: '100%' }} className={styles.uiImagePreview__footer}>
          <UiSpace direction="vertical" className={styles.uiImagePreview__info}>
            {customize?.title}
            {customize?.subtitle}
          </UiSpace>
          <UiSpace size={48} className={styles.uiImagePreview__actions}>
            <UiSpace size={spaceXL}>
              {customize?.onDownload && (
                <UiImagePreviewButton icon={DownloadSvg} onClick={customize.onDownload} />
              )}
              {customize?.onDelete && <UiImagePreviewButton icon={DeleteSvg} onClick={customize.onDelete} />}
              {customize?.onShare && <UiImagePreviewButton icon={ShareSvg} onClick={customize.onShare} />}
            </UiSpace>
            <UiSpace size={spaceXL}>
              <UiImagePreviewButton icon={RotateLeftSvg} onClick={onRotateLeft} />
              <UiImagePreviewButton icon={RotateSvg} onClick={onRotateRight} />
            </UiSpace>
            <UiSpace size={spaceXL}>
              <UiImagePreviewButton icon={ZoomInSvg} onClick={onZoomIn} disabled={scale === SCALE_MAX} />
              <UiImagePreviewButton icon={ZoomOutSvg} disabled={scale === SCALE_MIN} onClick={onZoomOut} />
            </UiSpace>
            <UiSpace size={spaceXL}>
              {customize?.content && (
                <UiImagePreviewButton
                  icon={CommentAltSvg}
                  active={isSidebarOpened}
                  onClick={() => toggleSidebarOpen()}
                />
              )}
            </UiSpace>
          </UiSpace>
        </UiRow>
      );

  const previewConfig = useMemo<AntImageProps['preview']>(() => {
    return {
      toolbarRender: getDefaultToolbar(isSidebarOpen),
      mask: <div style={{ cursor: 'pointer' }} />,
      scaleStep: SCALE_STEP,
      minScale: SCALE_MIN,
      maxScale: SCALE_MAX,
      closeIcon: <UiIcon width={20} height={20} component={CloseSvg} />,
      ...restPreviewConfig,
    };
  }, [isSidebarOpen]);

  return (
    <UiImageCustomContentPreview
      ref={ref}
      content={customize?.content}
      parentElement={containerElement}
      isDrawerOpen={isSidebarOpen}
      toggleDrawer={() => toggleSidebarOpen(false)}
      placement={customize?.sideBarPlacement}
    >
      <AntImage.PreviewGroup
        preview={{
          rootClassName: classNames(
            styles.uiImagePreview,
            isSidebarOpen && styles.uiImagePreview_sidebar_opened,
            customize?.sideBarPlacement === 'left'
              ? styles.uiImagePreview_sidebarPosition_left
              : styles.uiImagePreview_sidebarPosition_right,
          ),
          getContainer,
          visible,
          onChange,
          current,
          onVisibleChange,
          ...(previewConfig as Record<string, unknown>),
        }}
        items={items}
      >
        {React.Children.map<ReactNode, ReactNode>(children, (child) => {
          if (React.isValidElement<UiImageProps>(child)) {
            return React.cloneElement(child, {});
          }

          return null;
        })}
      </AntImage.PreviewGroup>
    </UiImageCustomContentPreview>
  );
};

UiImagePreview.Button = UiImagePreviewButton;
