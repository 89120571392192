import { Layout } from 'antd';
import classNames from 'classnames';
import React, { FC, PropsWithChildren, HTMLAttributes, useCallback, useRef } from 'react';

import { useOnClickOutside } from '../../../hooks';
import { variables } from '../../../providers/theme';
import { UiDrawer } from '../../drawer';
import { useBreakpoint, useLayout } from '../hooks';
import styles from './LayoutNavbar.scss';

type Props = HTMLAttributes<HTMLDivElement>;

export const LayoutNavbar: FC<PropsWithChildren<Props>> = (props) => {
  const { children, className, ...rest } = props;

  const { isNavbarCollapsed, toggleNavbar } = useLayout();
  const breakpoints = useBreakpoint();
  const containerRef = useRef<HTMLDivElement>(null);

  const onClickOutside = useCallback(() => {
    if (!isNavbarCollapsed) {
      toggleNavbar?.();
    }
  }, [breakpoints, toggleNavbar, isNavbarCollapsed]);

  useOnClickOutside(containerRef, onClickOutside);

  const widthDrawer =
    (breakpoints.sm && !breakpoints.md) || breakpoints.xs ? '100%' : variables.layoutSizes.navbarWidth;

  if (breakpoints.xl) {
    return (
      <Layout.Sider
        theme="light"
        className={styles.layoutNavbar}
        style={{ background: 'transparent' }}
        width={variables.layoutSizes.navbarWidth}
      >
        <div
          style={{ width: variables.layoutSizes.navbarWidth }}
          className={classNames(
            styles.layoutNavbar__scrollable,
            styles.layoutNavbar__container,
            styles.layoutNavbar__shadow,
          )}
        >
          {children}
        </div>
      </Layout.Sider>
    );
  }

  return (
    <div
      ref={containerRef}
      {...rest}
      className={classNames(styles.layoutNavbar, styles.layoutNavbar__shadow, className)}
    >
      <UiDrawer
        mask={false}
        width={widthDrawer}
        maskClosable
        placement="left"
        closeIcon={null}
        open={!isNavbarCollapsed}
        getContainer={false}
        forceRender
      >
        {children}
      </UiDrawer>
    </div>
  );
};
