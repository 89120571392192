import React from 'react';

import ClearSvg from '@vkph/ui/svg/clear.svg';

import { UiButton } from '../../button';
import { UiIcon } from '../../icon';

export const UiDatePickerClearIcon = (
  <UiButton type="link-secondary" icon={<UiIcon width={20} height={20} component={ClearSvg} />} />
);
