import { Popover as AntPopover } from 'antd';
import { PopoverProps } from 'antd/es/popover';
import classNames from 'classnames';
import React, { CSSProperties, FC } from 'react';

import { UiButtonProps } from '../button';
import { UiFlexProps } from '../flex';
import styles from './UiPopover.scss';
import { UiPopoverContent } from './content/UiPopoverContent';

export interface UiPopoverProps extends PopoverProps {
  actions?: Array<UiButtonProps>;
  actionsStyles?: CSSProperties;
  contentContainerGap?: UiFlexProps['gap'];
}

export const UiPopover: FC<UiPopoverProps> = (props) => {
  const { children, content, actions, className, actionsStyles, contentContainerGap, ...restProps } = props;

  return (
    <AntPopover
      className={classNames(className, styles.uiPopover)}
      content={
        <UiPopoverContent
          content={content}
          actions={actions}
          actionsStyles={actionsStyles}
          contentContainerGap={contentContainerGap}
        />
      }
      {...restProps}
    >
      {children}
    </AntPopover>
  );
};
