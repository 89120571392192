import { SortableContext } from '@dnd-kit/sortable';

import { UiDnDSortableDragIcon } from './drag-icon/UiDnDSortableDragIcon';
import { UiDnDSortableDragItem } from './drag-item/UiDnDSortableDragItem';
import { UiDnDSortableItem } from './item/UiDnDSortableItem';

export const UiDnDSortable = {
  Context: SortableContext,
  Item: UiDnDSortableItem,
  DragIcon: UiDnDSortableDragIcon,
  DragItem: UiDnDSortableDragItem,
};
