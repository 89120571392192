import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';

import { useSpace } from '../../../hooks';
import { UiFlex } from '../../flex';
import { UiRow } from '../../grid';
import { UiTypography } from '../../typography';

export interface CustomSuggestionsContainerProps {
  hasMore?: boolean;
  isScrollable?: boolean;
}

export const CustomSuggestionsContainer: FC<PropsWithChildren<CustomSuggestionsContainerProps>> = (props) => {
  const { children, hasMore, isScrollable } = props;
  const elRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const element = elRef?.current;

    if (element) {
      disableBodyScroll(element);
    }

    return () => {
      if (element) {
        enableBodyScroll(element);
      }
    };
  }, [elRef]);

  const { spaceM, spaceXS } = useSpace();

  const content = (
    <>
      {children}
      {hasMore && (
        <UiRow padding={[spaceXS, spaceM]}>
          <UiTypography.Text type="secondary">Уточните запрос</UiTypography.Text>
        </UiRow>
      )}
    </>
  );

  return isScrollable ? (
    <UiFlex vertical style={{ maxHeight: 450, overflow: 'auto' }} ref={elRef}>
      {content}
    </UiFlex>
  ) : (
    content
  );
};
