import classNames from 'classnames';
import React, { FC } from 'react';

import FaqSvg from '../../../svg/faq.svg';
import { UiTooltip, UiTooltipProps } from '../../tooltip';
import { UiIcon, UiIconProps } from '../UiIcon';
import styles from './UiIconHint.scss';

export interface UiIconHintProps extends UiIconProps {
  tooltipProps: UiTooltipProps;
}

export const UiIconHint: FC<UiIconHintProps> = (props) => {
  const { tooltipProps, ...iconProps } = props;

  return (
    <UiTooltip trigger="hover" placement="top" {...tooltipProps}>
      <span className={classNames(styles.uiIconHint)}>
        <UiIcon
          width={20}
          height={20}
          component={FaqSvg}
          className={classNames(styles.uiIconHint__icon)}
          {...iconProps}
        />
      </span>
    </UiTooltip>
  );
};
