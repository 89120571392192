// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f49d7568_ui-card-overlay{z-index:3;width:100%;box-shadow:0px 8px 16px rgba(46,48,51,.08);border:none}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/card/overlay/UiCardOverlay.scss","webpack://./../../libs/ui/src/assets/scss/variables/box-shadows.scss"],"names":[],"mappings":"AACA,0BACE,SAAA,CACA,UAAA,CACA,0CCGiB,CDFjB,WAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ui-card-overlay {\n  z-index: $zIndexOverlay + 1;\n  width: 100%;\n  box-shadow: $shadowActiveItem;\n  border: none\n}\n","$fade32: rgba(31, 32, 34, 0.32);\n$fade64: rgba(31, 32, 34, 0.64);\n$shadow2px: 0px 1px 2px rgba(46, 48, 51, 0.08);\n$shadow4px: 0px 2px 4px rgba(46, 48, 51, 0.08);\n$shadow8px: 0px 4px 8px rgba(46, 48, 51, 0.08);\n$shadow16px: 0px 2px 16px rgba(46, 48, 51, 0.08);\n$shadowAvatar: inset 0px 0px 4px rgba(41, 44, 46, 0.08);\n$shadowActiveItem: 0px 8px 16px rgba(46, 48, 51, 0.08);\n$shadowSmall: 0px 1px 4px rgba(17, 17, 17, 0.12);\n$shadowInput: 0 0 0 1px $colorBrandFaded;\n$shadowSwitch: 0 0 0 2px $colorBrandFaded;\n$shadow4pxSelect: 0 0 0 4px $colorBrand;\n$shadow2pxBtn: 0 0 0 2px $colorBrandFaded;\n$shadow2pxBtnNegative08: 0 0 0 2px $colorNegative08;\n$shadow2pxBtnNegativeFaded: 0 0 0 2px $colorNegativeFaded;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-card-overlay": "f49d7568_ui-card-overlay",
	"uiCardOverlay": "f49d7568_ui-card-overlay"
};
export default ___CSS_LOADER_EXPORT___;
