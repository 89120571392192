import { Drawer, DrawerProps } from 'antd';
import classNames from 'classnames';
import React, { FC, MouseEventHandler } from 'react';

import CloseSvg from '../../svg/close.svg';
import { UiButton } from '../button';
import { UiIcon } from '../icon';
import styles from './UiDrawer.scss';

export interface UiDrawerProps extends Omit<DrawerProps, 'visible' | 'afterVisibleChange'> {
  direction?: 'row' | 'row-reverse';
}

export const UiDrawer: FC<UiDrawerProps> = (props) => {
  const { height = 'auto', onClose, style, direction = 'row-reverse', onClick, ...restProps } = props;
  const onStopPropagationClick: MouseEventHandler<HTMLBaseElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onClose?.(e);
  };

  const defaultCloseIcon = (
    <UiButton
      type="link-secondary"
      icon={<UiIcon width={20} height={20} component={CloseSvg} />}
      onClick={onStopPropagationClick}
    />
  );

  return (
    <Drawer
      height={height}
      rootClassName={classNames(styles.uiDrawer, {
        [styles.uiDrawer_directionRow]: direction === 'row',
      })}
      closeIcon={defaultCloseIcon}
      onClose={onClose}
      {...restProps}
    />
  );
};
