import { Modal as AntModal } from 'antd';
import classNames from 'classnames';
import React, { FC, PropsWithChildren, useMemo } from 'react';

import { UiSpinner } from '../../spinner';
import { UiModalContent, UiModalContentComponent } from './content';
import { UiModalContext, UiModalContextState } from './context';
import { ModalFooterComponent, UiModalFooter } from './footer';
import { UiModalHeader, UiModalHeaderComponent } from './header';
import { getUiModalBodyStyle } from './helpers';
import styles from './styles.scss';
import { UiModalTypes } from './typings';

export { type UiModalHeaderProps, type UiModalHeaderTitleProps } from './header';
export { type UiModalFooterProps, type UiModalFooterButtonsProps } from './footer';

const ccn = classNames.bind(styles);

export interface UiModalProps {
  className?: string;
  type: UiModalTypes;
  onClose: () => void;
  onAfterClose?: () => void;
  isOpen?: boolean;
  isLoading?: boolean;
  isMaskClosable?: boolean;
}

type ModalComposition = {
  useModal: typeof AntModal.useModal;
  Header: UiModalHeaderComponent;
  Content: UiModalContentComponent;
  Footer: ModalFooterComponent;
};

export const UiModal: FC<PropsWithChildren<UiModalProps>> & ModalComposition = (props) => {
  const { type, children, className, isOpen, onClose, onAfterClose, isLoading, isMaskClosable } = props;

  const modalClassName = ccn(styles.uiModal, className, styles[`uiModal_type_${type}`]);

  const contextValue: UiModalContextState = useMemo(() => ({ onClose }), [onClose]);

  const modalBodyStyle = useMemo(() => getUiModalBodyStyle(type), [type]);

  return (
    <UiModalContext.Provider value={contextValue}>
      <AntModal
        className={modalClassName}
        width="auto"
        footer={null}
        onCancel={onClose}
        destroyOnClose
        afterClose={onAfterClose}
        open={isOpen}
        styles={{ body: modalBodyStyle }}
        closable={false}
        maskClosable={isMaskClosable}
      >
        {isLoading ? <UiSpinner /> : children}
      </AntModal>
    </UiModalContext.Provider>
  );
};

UiModal.useModal = AntModal.useModal;
UiModal.Header = UiModalHeader;
UiModal.Content = UiModalContent;
UiModal.Footer = UiModalFooter;
