import { ColorPicker } from 'antd';
import type { ColorPickerProps, ColorValueType } from 'antd/es/color-picker/interface';
import React, { FC } from 'react';

import { UiColorPickerButton } from './button/UiColorPickerButton';

export type { ColorPickerProps as UiColorPickerProps } from 'antd';

export type UiColorValueType = ColorValueType;
type UiColorPickerComposition = {
  Button: typeof UiColorPickerButton;
};

export const UiColorPicker: FC<ColorPickerProps> & UiColorPickerComposition = (props) => (
  <ColorPicker {...props} />
);

UiColorPicker.Button = UiColorPickerButton;
