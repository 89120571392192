import { FC } from 'react';

import { UiToggleButton as ToggleButton, UiToggleButtonProps } from './UiToggleButton';
import { UiToggleButtonGroup } from './group/UiToggleButtonGroup';

export * from './UiToggleButton';
export type { UiToggleButtonGroupProps } from './group/UiToggleButtonGroup';

interface UiToggleButtonComponent extends FC<UiToggleButtonProps> {
  Group: typeof UiToggleButtonGroup;
}

export const UiToggleButton = ToggleButton as UiToggleButtonComponent;

UiToggleButton.Group = UiToggleButtonGroup;
