import { Card as AntCard } from 'antd';

import { UiCard as Card, UiCardComponent } from './UiCard';
import { UiCardContent } from './content/UiCardContent';
import { UiCardFooter } from './footer/UiCardFooter';
import { UiCardHeader } from './header/UiCardHeader';
import { UiCardOverlay } from './overlay/UiCardOverlay';

export * from './UiCard';
export type { UiCardHeaderProps } from './header/UiCardHeader';
export type { UiCardHeaderTitleProps } from './header/title/UiCardHeaderTitle';

export interface UiCardComposition {
  Grid: typeof AntCard.Grid;
  Meta: typeof AntCard.Meta;
  Header: typeof UiCardHeader;
  Footer: typeof UiCardFooter;
  Content: typeof UiCardContent;
  Overlay: typeof UiCardOverlay;
}

export const UiCard = Card as UiCardComponent & UiCardComposition;

UiCard.Grid = AntCard.Grid;
UiCard.Meta = AntCard.Meta;
UiCard.Header = UiCardHeader;
UiCard.Footer = UiCardFooter;
UiCard.Content = UiCardContent;
UiCard.Overlay = UiCardOverlay;
