import React, { FC, PropsWithChildren } from 'react';

import DragIcon from '@vkph/ui/svg/drag.svg';

import { useSpace } from '../../../../hooks';
import { UiFlex } from '../../../flex';
import { UiIcon } from '../../../icon';
import { UiTruncateMarkup } from '../../../truncate-markup';
import styles from './UiTableDnDCellOverlay.scss';

interface UiTableDnDCellOverlayProps {
  grabIcon?: boolean;
}

export const UiTableDnDCellOverlay: FC<PropsWithChildren<UiTableDnDCellOverlayProps>> = (props) => {
  const { children, grabIcon = true } = props;
  const { spaceXS } = useSpace();

  return (
    <UiFlex className={styles.uiTableDndCellOverlay} justify="space-between" gap={spaceXS}>
      <UiTruncateMarkup truncate lines={1}>
        {children}
      </UiTruncateMarkup>
      {grabIcon && <UiIcon width={20} height={20} component={DragIcon} />}
    </UiFlex>
  );
};
