import React, { PropsWithChildren } from 'react';

import { useDnDSortable, UseDnDSortableProps } from '../../hooks';
import { UiDnDSortableItem, UiDnDSortableItemProps } from '../item/UiDnDSortableItem';

export interface UiDnDSortableDragItemProps<Data>
  extends Pick<UseDnDSortableProps<Data>, 'id' | 'data'>,
    PropsWithChildren<Partial<UiDnDSortableItemProps>> {
  useDragOverlay?: boolean;
}

export const UiDnDSortableDragItem = <Data,>(props: UiDnDSortableDragItemProps<Data>) => {
  const { id, data, disabled, hoverable, children, useForwardRef, className, style } = props;
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useDnDSortable<Data>({
    id,
    data,
    disabled,
  });

  return (
    <UiDnDSortableItem
      ref={setNodeRef}
      disabled={disabled}
      hoverable={hoverable}
      isDragging={isDragging}
      style={style}
      className={className}
      {...attributes}
      listeners={listeners}
      transform={transform}
      transition={transition}
      useForwardRef={useForwardRef}
    >
      {children}
    </UiDnDSortableItem>
  );
};
