import { Table as AntTable } from 'antd';
import {
  TableProps as AntTableProps,
  TablePaginationConfig as AntTablePaginationConfig,
  ColumnType as AntTableColumnType,
  ColumnGroupType as AntTableColumnGroupType,
} from 'antd/es/table';
import {
  FilterConfirmProps as AntTableFilterConfirmProps,
  SorterResult as AntTableSorterResult,
  TableRowSelection as AntTableRowSelection,
} from 'antd/es/table/interface.js';
import classNames from 'classnames';
import React, { ReactNode, useMemo, useCallback } from 'react';

import TableFilterSVG from '@vkph/ui/svg/table-filter.svg';

import { UiButton } from '../button';
import { UiIcon } from '../icon';
import { UiSpinner } from '../spinner';
import styles from './UiTable.scss';
import { UiTableActionsPanel } from './actions-panel';
import { UiTableCellAvatar } from './cell-avatar/UiTableCellAvatar';
import { UiTableCellDash } from './cell-dash/UiTableCellDash';
import { UiTableColumnSearch, UiTableColumnFilter, UiTableFilterDropdown } from './column';
import { useTableDnDConfig, TableDnDConfigProps } from './dnd';
import { UiTableSelectionHeader } from './selection-header/UiTableSelectionHeader';

type ColumnRender<RecordType> = Required<Pick<AntTableColumnType<RecordType>, 'render'>>['render'];
type ColumnRenderReturn<RecordType> = ReturnType<ColumnRender<RecordType>>;
type UiColumnRender<RecordType> = {
  (
    column: ColumnType<RecordType>,
    ...args: Parameters<ColumnRender<RecordType>>
  ): ColumnRenderReturn<RecordType>;
};

export type UiTableRowSelection<T> = AntTableRowSelection<T>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface UiTableProps<T extends object = any>
  extends Omit<AntTableProps<T>, 'rowClassName' | 'loading' | 'columns' | 'dataSource' | 'rowSelection'> {
  loading?: boolean;
  activeRow?: boolean;
  rowClassName?: string;
  disabled?: boolean;
  columns?: ColumnsType<T>;
  dataSource?: T[];
  draggableProps?: TableDnDConfigProps<T>;
  rowSelection?: UiTableRowSelection<T>;
  isTitleEmptyPadding?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UiTable = <RecordType extends Record<string, any>>(props: UiTableProps<RecordType>) => {
  const {
    className,
    rowClassName,
    activeRow,
    columns,
    dataSource,
    pagination,
    loading,
    onRow,
    disabled,
    components,
    draggableProps,
    rowSelection,
    footer,
    isTitleEmptyPadding,
  } = props;

  const renderColumn = useCallback<UiColumnRender<RecordType>>(
    (column, value, record, index) => {
      const { render, clickable } = column || {};
      const renderedColumn = render ? render(value, record, index) : value;
      const isRowClickable = clickable && onRow;

      if (isRowClickable) {
        return (
          <UiButton.Decorator
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            {renderedColumn}
          </UiButton.Decorator>
        );
      }

      return renderedColumn;
    },
    [onRow],
  );

  const tableDnDConfig = useTableDnDConfig<RecordType>({
    columns,
    dataSource,
    ...draggableProps,
  });

  const tableColumns = useMemo<UiTableProps<RecordType>['columns']>(() => {
    const internalColumns = draggableProps ? tableDnDConfig.modifiedColumns : columns;

    return internalColumns?.map((column) => ({
      ...column,
      filterIcon: column.filterIcon || <UiIcon width={20} height={20} component={TableFilterSVG} />,
      render: (...args) => renderColumn(column, ...args),
    }));
  }, [columns, renderColumn, tableDnDConfig, draggableProps]);

  const tableComponents = useMemo<UiTableProps<RecordType>['components']>(() => {
    if (draggableProps) {
      const { BodyDnD, BodyDnDRow, HeaderDnD, HeaderDnDCell } = tableDnDConfig;

      return {
        ...components,
        header: {
          cell: HeaderDnDCell,
          wrapper: HeaderDnD,
        },
        body: {
          row: BodyDnDRow,
          wrapper: BodyDnD,
        },
      };
    }

    return components;
  }, [tableDnDConfig, draggableProps]);

  return (
    <AntTable
      {...props}
      loading={{ indicator: UiSpinner.Indicator, spinning: Boolean(loading), size: 'large' }}
      columns={tableColumns}
      footer={footer}
      dataSource={dataSource}
      pagination={pagination}
      className={classNames(
        styles.uiTable,
        {
          [styles.uiTable_disabled]: disabled,
          [styles.uiTable__title_emptyPadding]: isTitleEmptyPadding,
          [styles.uiTable_headerCellHidden]: isTitleEmptyPadding && rowSelection?.selectedRowKeys?.length,
          [styles.uiTable_hasFooter]: Boolean(footer),
        },
        className,
      )}
      rowClassName={classNames(
        {
          [styles.uiTable__activeRow]: activeRow,
        },
        rowClassName,
      )}
      components={tableComponents}
    />
  );
};

UiTable.CellDash = UiTableCellDash;
UiTable.CellAvatar = UiTableCellAvatar;
UiTable.ActionsPanel = UiTableActionsPanel;
UiTable.ColumnSearch = UiTableColumnSearch;
UiTable.ColumnFilter = UiTableColumnFilter;
UiTable.FilterDropdown = UiTableFilterDropdown;
UiTable.SelectionHeader = UiTableSelectionHeader;

type ColumnClickableParams = {
  clickable?: boolean;
};

export type ColumnType<T> = AntTableColumnType<T> & ColumnClickableParams;
export type TableColumnGroupType<T> = AntTableColumnGroupType<T> & ColumnClickableParams;
export type ColumnsType<T = unknown> = (TableColumnGroupType<T> | ColumnType<T>)[];

export type TableRowSelection<T> = AntTableRowSelection<T>;

export type FilterConfirmProps = AntTableFilterConfirmProps;
export type Filters = Parameters<NonNullable<UiTableProps['onChange']>>[1];
export type FilterValue = Filters[string];
export type SorterResult<T> = AntTableSorterResult<T>;

export type TableDataCells = Record<string, ReactNode>;
export type UiTableDataKey<K = string> = {
  key: K;
};
export type TableData = {
  key: string | number;
} & TableDataCells;

export type TablePaginationConfig = AntTablePaginationConfig;
