import { Typography } from 'antd';
import { BaseType } from 'antd/lib/typography/Base';
import { TextProps } from 'antd/lib/typography/Text';
import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './UiTypographyText.scss';

export type UiTypographyTextType = BaseType | UiTypographyTextTypes;
export interface UiTypographyTextProps extends Omit<TextProps, 'type'> {
  type?: UiTypographyTextType;
}

export enum UiTypographyTextTypes {
  Primary = 'primary',
  Tertiary = 'tertiary',
  Warning = 'warning',
  Secondary = 'secondary',
  Danger = 'danger',
  Invert = 'invert',
}

export const UiTypographyText: FC<UiTypographyTextProps> = (props) => {
  const { className, type = UiTypographyTextTypes.Primary, ...restProps } = props;
  const isType = (key: UiTypographyTextTypes) => type === key;
  const classNameStyles = classNames(
    {
      [styles.uiTypographyText_secondary]: isType(UiTypographyTextTypes.Secondary),
      [styles.uiTypographyText_danger]: isType(UiTypographyTextTypes.Danger),
      [styles.uiTypographyText_warning]: isType(UiTypographyTextTypes.Warning),
      [styles.uiTypographyText_tertiary]: isType(UiTypographyTextTypes.Tertiary),
      [styles.uiTypographyText_invert]: isType(UiTypographyTextTypes.Invert),
    },
    styles.uiTypographyText,
    className,
  );

  return <Typography.Text {...restProps} className={classNameStyles} />;
};
