import { Menu as AntMenu } from 'antd';
import { FC } from 'react';

import { UiMenu as Menu, UiMenuProps } from './UiMenu';
import { UiMenuCollapsible } from './collapsible/UiMenuCollapsible';

export * from './UiMenu';
export * from './collapsible/types';
export type { UiMenuCollapsibleProps } from './collapsible/UiMenuCollapsible';

type UiMenuComposition = {
  Divider: typeof AntMenu.Divider;
  Item: typeof AntMenu.Item;
  SubMenu: typeof AntMenu.SubMenu;
  ItemGroup: typeof AntMenu.ItemGroup;
  Collapsible: typeof UiMenuCollapsible;
};

export const UiMenu = Menu as FC<UiMenuProps> & UiMenuComposition;

UiMenu.Divider = AntMenu.Divider;
UiMenu.Item = AntMenu.Item;
UiMenu.SubMenu = AntMenu.SubMenu;
UiMenu.ItemGroup = AntMenu.ItemGroup;
UiMenu.Collapsible = UiMenuCollapsible;
