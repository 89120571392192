import { ThemeOptions, ThemeVariables } from './types';
import * as variables from './variables';

const THEME_VARIABLES = 'themeVariables';

export const themeOptionsDefault: ThemeOptions = {
  name: process.env.CUSTOMER || 'default',
  varsPrefix: ['--', process.env.CUSTOMER, process.env.CUSTOMER && '-', THEME_VARIABLES].join(''),
  variables: variables[THEME_VARIABLES] as ThemeVariables,
  images: {},
};
