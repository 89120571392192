import { useZIndex } from 'antd/es/_util/hooks/useZIndex';
import classNames from 'classnames';
import React, { FC } from 'react';
import {
  MentionItem,
  MentionsInput,
  MentionsInputProps,
  OnChangeHandlerFunc,
  SuggestionDataItem,
} from 'react-mentions';

import { useGetSizeClassname } from '../../../hooks';
import { UiConfigProviderSizeType } from '../../config-provider';
import {
  CustomSuggestionsContainer,
  CustomSuggestionsContainerProps,
} from '../suggestion/CustomSuggestionsContainer';
import styles from './UiMentionsInput.scss';
import { UiMentionClearInputSearchHandler as MentionClearInputSearchHandler } from './search/UiMentionInputSearch';

export type UiMentionsInputValue = string;
export type UiMentionItem = MentionItem;
export type UiMentionSuggestionItem = SuggestionDataItem;
export type OnChangeMentionsInputHandler = (
  newValue: UiMentionsInputValue,
  newPlainTextValue: UiMentionsInputValue,
  mentions: UiMentionItem[],
  event: { target: { value: UiMentionsInputValue } },
) => void;

export type UiMentionClearInputSearchHandler = MentionClearInputSearchHandler;

export interface UiMentionsInputProps
  extends Omit<MentionsInputProps, 'onChange'>,
    CustomSuggestionsContainerProps {
  onChange?: OnChangeMentionsInputHandler;
  noStyle?: boolean;
  size?: UiConfigProviderSizeType;
  getPopupContainer?: (triggerNode?: HTMLElement) => HTMLElement;
}

export const UiMentionsInput: FC<UiMentionsInputProps> = (props) => {
  const {
    value = '',
    onChange,
    style,
    className,
    size: customizeSize,
    noStyle,
    getPopupContainer,
    hasMore,
    isScrollable = true,
    ...restProps
  } = props;
  const sizeCls = useGetSizeClassname(customizeSize);
  const [zIndex] = useZIndex('Popover');
  const classNameStyle = classNames(
    styles.uiMentionsInput,
    {
      [styles.uiMentionsInput_noStyle]: noStyle,
      [`${styles.uiMentionsInput}-${sizeCls}`]: sizeCls && !noStyle,
    },
    className,
  );

  const onChangeHandler: OnChangeHandlerFunc = (event, ...args) => {
    onChange?.(...args, event);
  };

  return (
    <MentionsInput
      a11ySuggestionsListLabel="Текст с ссылками через '@'"
      {...restProps}
      value={value}
      autoComplete="off"
      onChange={onChangeHandler}
      className={classNameStyle}
      customSuggestionsContainer={(children) => (
        <CustomSuggestionsContainer hasMore={hasMore} isScrollable={isScrollable}>
          {children}
        </CustomSuggestionsContainer>
      )}
      suggestionsPortalHost={getPopupContainer?.() || document.body}
      style={{
        highlighter: noStyle && {
          border: 0,
        },
        suggestions: {
          zIndex,
          list: { backgroundColor: 'none' },
        },
        '&singleLine': {
          input: { backgroundColor: 'none' },
        },
        ...style,
      }}
    />
  );
};
