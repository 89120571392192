import React, { FC } from 'react';

import DropDownSvg from '@vkph/ui/svg/drop-down.svg';
import DropUpSvg from '@vkph/ui/svg/drop-up.svg';

import { UiButton } from '../../../button';
import { UiFormListItemMoveActionType } from '../../../form';
import { UiIcon } from '../../../icon';
import { UiMenuItemTypes } from '../../UiMenu';

type UiMenuCollapsibleControlsProps = {
  onMove: UiFormListItemMoveActionType;
  index: number;
  items: UiMenuItemTypes[];
};

export const UiMenuCollapsibleControls: FC<UiMenuCollapsibleControlsProps> = (props) => {
  const { onMove, index, items } = props;

  const handleUpClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onMove?.(index, index - 1);
  };

  const handleDownClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onMove?.(index, index + 1);
  };

  return (
    <div>
      <UiButton type="link-secondary" onClick={handleUpClick} disabled={!index}>
        <UiIcon width={20} height={20} component={DropUpSvg} />
      </UiButton>
      <UiButton type="link-secondary" onClick={handleDownClick} disabled={items.length - 1 === index}>
        <UiIcon width={20} height={20} component={DropDownSvg} />
      </UiButton>
    </div>
  );
};
