import classNames from 'classnames';
import React, { FC } from 'react';

import { AbsolutePlacement } from '../../hooks';
import { variables } from '../../providers/theme';
import { UiCheckbox } from '../checkbox';
import { UiImage, UiImageProps } from '../image';
import { UiOverlay, UiOverlayProps } from '../overlay';
import { UiRadio } from '../radio';
import styles from './UiImageOption.scss';

export interface UiImageOptionProps extends UiImageProps {
  textClassName?: string;
  checked?: boolean;
  value?: number | string;
  onSelect?: () => void;
  type?: 'radio' | 'checkbox';
  text?: string;
  isChoiceComponentHidden?: boolean;
  overlayProps?: Partial<UiOverlayProps>;
}

export const UiImageOption: FC<UiImageOptionProps> = (props) => {
  const {
    src,
    wrapperClassName,
    checked,
    onSelect,
    type = 'checkbox',
    value,
    className,
    children,
    width = 100,
    height = 100,
    isChoiceComponentHidden = false,
    overlayProps,
  } = props;

  const ChoiceComponent = type === 'radio' ? UiRadio : UiCheckbox;

  const propsWithValue = value ? { value } : {};

  return (
    <UiOverlay
      trigger="hover"
      isVisible={!checked}
      overlayStyles={{
        background: variables.themePalette.colorFade64,
      }}
      {...overlayProps}
    >
      <div style={{ height, width }} className={classNames(styles.uiImageOption, wrapperClassName)}>
        <ChoiceComponent
          placement={AbsolutePlacement.TopRight}
          checked={checked}
          {...propsWithValue}
          onChange={onSelect}
          className={classNames(
            styles.uiImageOption__choice,
            isChoiceComponentHidden && styles.uiImageOption__choice_hidden,
          )}
        >
          <div className={styles.uiImageOption__childrenWrapper}>{children}</div>
        </ChoiceComponent>

        <UiImage
          width={width}
          height={height}
          alt="cover"
          src={src}
          className={classNames(styles.uiImageOption__img, className)}
        />
      </div>
    </UiOverlay>
  );
};
