import classNames from 'classnames';
import React, { FC, MouseEvent } from 'react';

import { useSpace } from '../../../../hooks';
import CloseIcon from '../../../../svg/close.svg';
import TableFilter from '../../../../svg/table-filter.svg';
import { UiButton } from '../../../button';
import { UiFlex } from '../../../flex';
import { UiIcon } from '../../../icon';
import { UiTypography } from '../../../typography';
import styles from './UiTableFilterButton.scss';

export interface UiTableFilterDropdownButtonProps {
  count: number;
  reset?: () => void;
}

export const UiTableFilterDropdownButton: FC<UiTableFilterDropdownButtonProps> = (props) => {
  const { count, reset } = props;
  const { space2XS } = useSpace();

  const onReset = (event: MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();

    reset?.();
  };

  return (
    <UiFlex
      gap={space2XS}
      align="center"
      className={classNames(styles.filterButton, count > 0 && styles.filterButton_filtered)}
    >
      <UiIcon component={TableFilter} width={20} height={20} />
      {count > 0 && (
        <>
          <UiTypography.Text>{count}</UiTypography.Text>
          <UiButton
            onClick={onReset}
            className={styles.filterButton__closeIcon}
            icon={<UiIcon component={CloseIcon} width={20} height={20} />}
            type="link-secondary"
          />
        </>
      )}
    </UiFlex>
  );
};
