import { PopoverProps } from 'antd/es/popover';
import React, { CSSProperties, FC } from 'react';

import { useSpace } from '../../../hooks';
import { UiButton, UiButtonProps } from '../../button';
import { UiFlex, UiFlexProps } from '../../flex';
import { UiRow } from '../../grid';
import { UiSpace } from '../../space';

export interface UiPopoverContentProps extends PopoverProps {
  actions?: Array<UiButtonProps>;
  actionsStyles?: CSSProperties;
  contentContainerGap?: UiFlexProps['gap'];
}

export const UiPopoverContent: FC<UiPopoverContentProps> = (props) => {
  const { content, actions, actionsStyles, contentContainerGap } = props;
  const { spaceS, spaceL } = useSpace();

  return (
    <UiFlex vertical gap={contentContainerGap}>
      <UiRow>{typeof content === 'function' ? content() : content}</UiRow>
      {actions && (
        <UiRow style={{ paddingTop: spaceL, paddingBottom: spaceS, ...actionsStyles }}>
          <UiSpace>
            {actions.map((action) => (
              <UiButton key={action.label} {...action} />
            ))}
          </UiSpace>
        </UiRow>
      )}
    </UiFlex>
  );
};
