import React, { forwardRef, ReactNode, FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

import { UiDrawer } from '../../../drawer';
import { UiTypography } from '../../../typography';

const DRAWER_WIDTH = 460;

export interface UiImageCustomContentPreviewProps {
  content?: {
    title: ReactNode;
    body: ReactNode;
  };
  parentElement?: HTMLDivElement | null;
  placement?: 'left' | 'right';
  isDrawerOpen?: boolean;
  toggleDrawer?: () => void;
}

export const UiImageCustomContentPreview: FC<
  PropsWithChildren<UiImageCustomContentPreviewProps> & React.RefAttributes<HTMLDivElement>
> = forwardRef<HTMLDivElement, PropsWithChildren<UiImageCustomContentPreviewProps>>((props, ref) => {
  const { children, content, parentElement, placement = 'right', isDrawerOpen, toggleDrawer } = props;

  return (
    <>
      {children}
      {createPortal(
        <div ref={ref}>
          {content && parentElement && (
            <UiDrawer
              title={
                <UiTypography.Title level={3} style={{ margin: 0 }}>
                  {content.title}
                </UiTypography.Title>
              }
              placement={placement}
              onClose={toggleDrawer}
              open={isDrawerOpen}
              getContainer={parentElement || false}
              width={DRAWER_WIDTH}
              mask={false}
              bodyStyle={{ padding: 0 }}
            >
              {content.body}
            </UiDrawer>
          )}
        </div>,
        document.body,
      )}
    </>
  );
});
