import classNames from 'classnames';
import React from 'react';

import { UiAvatar } from '../../avatar';
import { UiSpace } from '../../space';
import { UiTypography } from '../../typography';
import { UiMentionProps } from '../UiMentions';

export type UiMentionUserSuggestion = {
  smallAvatar: string;
};

export const renderSuggestion: UiMentionProps['renderSuggestion'] = (
  _,
  __,
  highlightedDisplay,
  ___,
  focused,
) => {
  return <div className={classNames('user', { focused })}>{highlightedDisplay}</div>;
};

export const renderUserSuggestion: UiMentionProps<UiMentionUserSuggestion>['renderSuggestion'] = (
  suggestion,
  __,
  highlightedDisplay,
  ___,
  focused,
) => {
  const { data } = suggestion;

  return (
    <UiSpace size={8} align="center" className={classNames('user', { focused })}>
      <UiAvatar size="extraSmall" src={data?.smallAvatar} />
      <UiTypography.Text>{highlightedDisplay}</UiTypography.Text>
    </UiSpace>
  );
};
