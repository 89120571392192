import classNames from 'classnames';
import React, {
  memo,
  Children,
  useEffect,
  forwardRef,
  cloneElement,
  isValidElement,
  HTMLAttributes,
  PropsWithChildren,
} from 'react';

import { UseDnDSortable, UseDnDSortableProps } from '../../hooks';
import styles from './UiDnDSortableItem.scss';

export interface UiDnDSortableItemProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'style'>,
    Partial<Omit<UseDnDSortable, 'data'>> {
  disabled?: UseDnDSortableProps<unknown>['disabled'];
  hoverable?: boolean;
  useForwardRef?: boolean;
}

export const UiDnDSortableItem = memo(
  forwardRef<HTMLDivElement, PropsWithChildren<UiDnDSortableItemProps>>((props, ref) => {
    const {
      style,
      className,
      children,
      isDragging,
      hoverable = true,
      disabled = false,
      listeners,
      transition,
      transform,
      useForwardRef,
      ...restProps
    } = props;

    useEffect(() => {
      if (!isDragging) {
        return () => {};
      }

      document.body.style.cursor = 'grabbing';

      return () => {
        document.body.style.cursor = '';
      };
    }, [isDragging]);

    let dndProps = {
      ref,
      ...restProps,
      ...listeners,
      'data-cypress': 'draggable-item',
      className: classNames(
        className,
        styles.uiDndSortableItem,
        disabled && styles.uiDndSortableItem_disabled,
        isDragging && styles.uiDndSortableItem_dragOverlay,
        hoverable && styles.uiDndSortableItem_hoverable,
      ),
      style: {
        ...style,
        transition,
        [styles.uiDnDSortableItemTranslateX]: transform ? `${Math.round(transform.x)}px` : undefined,
        [styles.uiDnDSortableItemTranslateY]: transform ? `${Math.round(transform.y)}px` : undefined,
      },
    };

    if (!useForwardRef) {
      return <div {...dndProps}>{children}</div>;
    }

    const element = Children.only(children);

    if (useForwardRef && isValidElement(element)) {
      dndProps = {
        ...dndProps,
        className: classNames(dndProps.className, element.props.className),
        style: {
          ...element.props.style,
          ...dndProps.style,
        },
      };

      return cloneElement(element, dndProps);
    }

    return null;
  }),
);
