import { Grid } from 'antd';
import React, { FC, PropsWithChildren } from 'react';

import { LayoutSiderColumn } from './column';
import { LayoutSiderContent, LayoutSiderContentProps } from './content';

type LayoutSiderComposition = {
  Column: typeof LayoutSiderColumn;
};

interface Props extends LayoutSiderContentProps {
  multiColumns?: boolean;
}

export const LayoutSider: FC<PropsWithChildren<Props>> & LayoutSiderComposition = (props) => {
  const { multiColumns, ...restProps } = props;
  const { children, ...restLayoutContentProps } = restProps;
  const breakpoints = Grid.useBreakpoint();

  if (multiColumns && breakpoints.xxl) {
    return React.Children.map(children, (child) => {
      return React.isValidElement(child) ? (
        <LayoutSiderContent key={child.key} {...restLayoutContentProps}>
          {child}
        </LayoutSiderContent>
      ) : null;
    });
  }

  return <LayoutSiderContent {...restProps} />;
};

LayoutSider.Column = LayoutSiderColumn;
