import tokens from '@vkph/tokens';
import { App } from 'antd';
import { updateCSS } from 'rc-util/lib/Dom/dynamicCSS';
import React, { useMemo, useCallback, useEffect, useRef, PropsWithChildren, FC } from 'react';

import { UiConfigProvider } from '../../components/config-provider';
import { getAntTheme } from './getAntTheme';
import { ThemeState, ThemeOptions, ThemeProviderProps } from './types';

type ThemeAction = (state: ThemeOptions) => void;

type ThemeContextValueType = [ThemeState, ThemeAction];

const ThemeContext = React.createContext<ThemeContextValueType | undefined>(undefined);

export const ThemeProvider: FC<PropsWithChildren<ThemeProviderProps>> = (props) => {
  const { children, theme, onChange } = props;
  const dynamicStyleMark = `${theme.varsPrefix}`;

  const updateCSSVariablesRoot = ({ variables }: ThemeState) => {
    const mergedVariables = { ...variables, ...tokens };

    const cssVariablesList = Object.keys(mergedVariables).map(
      (key) => `${theme.varsPrefix}-${key}: ${mergedVariables[key]};`,
    );

    updateCSS(`:root {${cssVariablesList.join('\n')}}`, dynamicStyleMark);
  };

  const onChangeRef = useRef(onChange);

  onChangeRef.current = onChange;

  const setTheme = useCallback((value: ThemeOptions) => {
    updateCSSVariablesRoot(value);

    if (onChangeRef.current) {
      onChangeRef.current(value);
    }
  }, []);

  useEffect(() => {
    updateCSSVariablesRoot(theme);
  }, [theme]);

  const value: ThemeContextValueType = useMemo(() => {
    const mergedVariables = { ...theme.variables, ...tokens };

    return [{ ...theme, variables: mergedVariables }, setTheme];
  }, [theme, setTheme]);

  const antTheme = useMemo(() => {
    return getAntTheme(theme);
  }, [theme]);

  return (
    <UiConfigProvider theme={antTheme}>
      <ThemeContext.Provider value={value}>
        <App component={false}>{children}</App>
      </ThemeContext.Provider>
    </UiConfigProvider>
  );
};

export const useTheme = (): [ThemeOptions, ThemeAction] => {
  const context = React.useContext(ThemeContext);

  if (!context) {
    throw new Error('Can not resolve ThemeContext into useTheme hook');
  }

  return context;
};
