import { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useWindowDimensions } from './useWindowDimensions';

export const useElementSize = <T extends HTMLElement = HTMLDivElement>(
  dependencies: ReadonlyArray<unknown> = [],
) => {
  const ref = useRef<T>(null);
  const { width: elementWidth, height: elementHeight } = useWindowDimensions({ throttleMs: 200 });

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const onResizeDebounced = useDebouncedCallback(() => {
    const refWidth = ref.current?.clientWidth || 0;
    const refHeight = ref.current?.clientHeight || 0;

    setWidth(refWidth);
    setHeight(refHeight);
  }, 300);

  useEffect(() => {
    if (!ref.current) return undefined;

    const resizeObserver = new ResizeObserver(onResizeDebounced);

    resizeObserver.observe(ref.current);

    return () => resizeObserver.disconnect();
  }, [elementWidth, elementHeight, ...dependencies]);

  return { ref, width, height };
};
